import { takeEvery, call, put } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services/";
import { history } from "helpers/history";

export default function* watcherAuthentication() {
  yield takeEvery(actionTypes.LOGIN_USER, loginUser);
  yield takeEvery(actionTypes.LOGOUT, logoutUser);
  yield takeEvery(actionTypes.FORGOTPASSWORD, forgotPassword);
  yield takeEvery(actionTypes.RESETPASSWORD, resetPassword);
  yield takeEvery(actionTypes.ALTADIRECTOR, registerDirector);
  yield takeEvery(actionTypes.ALTAINVESTIGADOR, registerInvestigador);
  yield takeEvery(actionTypes.ALTAOTROS, registerOtros);
  yield takeEvery(actionTypes.CHANGEPASSWORD, changepwd);
  yield takeEvery(actionTypes.FIRSTPASSWORD, firstpwd);
  yield takeEvery(actionTypes.REMOVEUSER, removeUSer);
  yield takeEvery("CONTACT", contact);
}

function* contact({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/contact";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: "CONTACT_DATA", payload: res.data });

      yield put({ type: "CLEAR_CONTACT_DATA", payload: res.data });
    } else {
      yield put({
        type: "CONTACT_ERROR",
        payload: res.data,
      });

      yield put({ type: "CLEAR_CONTACT_DATA", payload: res.data });
    }
  } catch (e) {
    yield put({ type: "CONTACT_ERROR", payload: e });
  }
}
function* registerDirector({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/user/director";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.ALTADIRECTOR_DATA, payload: res.data });
      if (res.data.code === 200) {
        yield put({ type: actionTypes.END_REGISTER });
        history.push("/user/register/ok");
      }
    } else {
      yield put({
        type: actionTypes.ALTADIRECTOR_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.ALTADIRECTOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* registerOtros({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/user/otros";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.ALTAOTROS_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.ALTAOTROS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.ALTAOTROS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* firstpwd({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/first";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.FIRSTPASSWORD_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.FIRSTPASSWORD_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.ALTAINVESTIGADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* changepwd({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/change";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.CHANGEPASSWORD_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.CHANGEPASSWORD_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.ALTAINVESTIGADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* registerInvestigador({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/user/investigador";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.ALTAINVESTIGADOR_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.ALTAINVESTIGADOR_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.ALTAINVESTIGADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* forgotPassword({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/reset";
    const res = yield call(
      () =>
        new Promise((resolve) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res && res.status && res.status === 200)
      yield put({ type: actionTypes.FORGOTPASSWORD_DATA, payload: res.data });
    else
      yield put({ type: actionTypes.FORGOTPASSWORD_ERROR, payload: res.status });
  } catch (e) {
    yield put({ type: actionTypes.FORGOTPASSWORD_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* resetPassword({ payload, token }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/change/" + token;
    const res = yield call(xcService.post, apiEndpoint, payload);

    if(res.status === 200)
      yield put({ type: actionTypes.RESETPASSWORD_DATA, payload: res.data });
    else
      yield put({ type: actionTypes.RESETPASSWORD_ERROR, payload: res.status });

  } catch (e) {
    yield put({ type: actionTypes.RESETPASSWORD_ERROR, payload: e.status });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* loginUser({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/login";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res) {
      if (res.status && res.status === 200 && res.data.token) {
        if (localStorage.getItem("xcfirstTimev2")){

        }else{
          localStorage.setItem("xcfirstTimev2", true);
        }
        localStorage.setItem("xcauth", "true");
      //  localStorage.setItem("xccompleto", "0");
        localStorage.setItem("xctoken", res.data.token);
        localStorage.setItem("xcrefreshToken", res.data.token);
        localStorage.setItem("xcuserv2", JSON.stringify(res.data));
        // console.log("USER",res.data);
        localStorage.removeItem("showVideo" + res.data.email);
        localStorage.removeItem("newUserv4" + res.data.email);
        localStorage.setItem("esPrimer", true);
      
        
        
        localStorage.setItem(
          "tour",
          localStorage.getItem("tour") != (undefined && null)
            ? localStorage.getItem("tour")
            : true
        );
        if (res.data.perfil === 2 || res.data.perfil === 0) {
          history.push("/auth/resultados");
        } else {
          history.push("/auth/start");
        }

        //yield put({ type: "LOGIN_DATA", payload: res });
      } else {
        yield put({ type: actionTypes.LOGIN_ERROR, payload: res.data });
      }
    } else {
      yield put({
        type: actionTypes.LOGIN_ERROR,
        payload: "Se ha producido un error",
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.LOGIN_ERROR,
      payload: "Se ha producido un error",
    });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* logoutUser() {
  yield put({ type: actionTypes.START_REQUEST });
  localStorage.setItem("xcauth", "false");
 // localStorage.setItem("xccompleto", "0");
  localStorage.removeItem("txcoken");
  localStorage.removeItem("xcrefreshToken");
  localStorage.removeItem("xcuserv2");
  localStorage.removeItem("avisoXCsalida");
  localStorage.removeItem("esPrimer");
  history.push("/user/dashboard");
  yield put({ type: actionTypes.END_REQUEST });
}

function* removeUSer() {
  yield put({ type: actionTypes.START_REQUEST });
  localStorage.setItem("xcauth", "false");
  //localStorage.setItem("xccompleto", "0");
  localStorage.removeItem("txcoken");
  localStorage.removeItem("xcrefreshToken");
  localStorage.removeItem("xcuserv2");
  localStorage.removeItem("avisoXCsalida");
  localStorage.removeItem("esPrimer");
  history.push("/user/delete");
  yield put({ type: actionTypes.END_REQUEST });
}
