import React from 'react';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import defaultImage from 'assets/img/default_image.jpeg';
import { Typography } from '@material-ui/core';
// import { enqueueSnackbar } from 'notistack';

export default function ImageField(props) {
  const handleChange = (event) => {
    const file = event.currentTarget.files?.[0];

    if(file) {
      const size = (file.size ?? 0) / 1024 / 1024;
      let message;

      if (size > 10) {
        message = 'Tamaño máximo permitido en 10Mb';
      }

      if(message) {
        // enqueueSnackbar(message, { variant: 'info' });
      } else {
        props.setFieldValue(props.name, file);
      }
    }
  };

  const previewUrl =
    props.value instanceof File
      ? URL.createObjectURL(props.value)
      : props.value
        ? `${process.env.REACT_APP_API}/${props.value}`
        : defaultImage;

  return (
    <div style={{width: 'fit-content', margin: '0 auto'}}>
      <div style={{height: 120, position: 'relative'}}>
        <img
          height="100%"
          src={previewUrl}
          alt="product_image"
        />
        <label
          style={{
            position: 'absolute',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0006',
            color: '#fff',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <BorderColorRoundedIcon fontSize="large" />
          <input
            style={{visibility: 'hidden', width: 0.1, height: 0.1}}
            accept="image/*"
            type="file"
            onChange={handleChange}
          />
        </label>
      </div>
      <Typography variant='caption'>{props.helper}</Typography>
    </div>
  );
}
