import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Language } from "components/Language.js";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import stainImage from "assets/img/stain_single.webp";

const useStyles = makeStyles(theme => ({
  keysGraphic: {
    backgroundImage: `url(${stainImage})`,
    backgroundPosition: '-80 center',
    backgroundSize: '385px',
    backgroundRepeat: 'no-repeat',

    '& > .container': {
      maxWidth: theme.breakpoints.values.md - 300,
      paddingTop: 50,
      paddingBottom: 50,

      [theme.breakpoints.up('sm')]: {
        paddingTop: 90,
        paddingBottom: 90,
      }
    },
  },
}));

export function WhatIsXcelence() {
  const classes = useStyles();

  return (
    <div className={classes.keysGraphic}>
      <div className="container">
        <Typography className="mb-4" variant="h5">
          <Language parse langKey="report_page.model_reference" />
        </Typography>
        <Button
          variant="contained"
          endIcon={<ExpandMoreIcon />}
          href="#send_form"
          color="primary">
          <Language langKey="report_page.download_study" />
        </Button>
      </div>
    </div>
  );
}
