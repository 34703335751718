import React, { useState } from "react";
import { Dialog, DialogContent, Typography, makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player";
import { YoutubePlayIcon } from "components/YoutubePlayIcon";

const useStyle = makeStyles({
  fegure: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  videoCard: ({height, width, color}) => ({
    width: width ?? 310,
    backgroundColor: color,
    height: height ?? 170,
    border: `2px solid ${color}`,
    position: 'relative',
    cursor: 'pointer',
  }),
  dialogContent: {
    padding: 0,
    position: 'relative',
    paddingTop: '56.25%', 
    overflow: 'hidden',
  }
});

export function YoutubeGridVideo(
  { color, height, width, video }
) {
  const [open, setOpen] = useState(false);
  const classes = useStyle({ color, height, width });

  return (
    <>
      <div onClick={setOpen.bind(null, true)} className={classes.videoCard}>
        <img
          height='100%'
          width="100%" 
          src={`https://img.youtube.com/vi/${video}/mqdefault.jpg`} />
        <YoutubePlayIcon className={classes.fegure} />
      </div>

      <Dialog
        keepMounted={false}
        fullWidth
        maxWidth="md"
        onClose={setOpen.bind(null, false)}
        open={open}
      >
        <span></span> {/* No remove */}
        <DialogContent className={classes.dialogContent}>
          <ReactPlayer
            style={{ position: 'absolute', top: 0, left: 0 }}
            children
            controls
            width='100%'
            playing={true}
            height='100%'
            url={`https://youtu.be/${video}`} />
        </DialogContent>
      </Dialog>
    </>
  );
}
