import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  featureTitle: ({color, fullWidth, textColor, borderBottom}) => ({
    backgroundColor: color,
    borderRadius: fullWidth ? undefined : 12,
    maxWidth: fullWidth ? undefined : 900,
    borderBottom: borderBottom ? '2px solid #e6e6e6' : undefined,
    margin: 'auto',
    padding: 20,
    '& div': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 20,
    },
    '& img': {
      width: 40,
    },
    '& h6': {
      color: '#fff',
      margin: 0,
      fontSize: '1.07rem',
    },
    '& h5': {
      color: textColor ? textColor : theme.palette.primary.main,
      margin: 'auto',
      fontWeight: 'bold',
      fontSize: '1.3rem',
    },
  }),
  featureDetail: ({textColor}) => ({
    backgroundColor: textColor ? '#e6e6e6' : '#fff',
    padding: '20px 10px',
    textAlign: 'center',
    '& > div': {
      padding: '2rem 25px',
      margin: 'auto',
      maxWidth: 900,
    },
    '& p': {
      textAlign: 'center',
    },
    '& a[class^=sw-btn]': {
      fontSize: '0.9rem',
      padding: '16px 30px',
    }
  })
}));

export function AwardsCategory(
  {color, icon, title, detail, fullWidth, textColor, borderBottom, id}
) {
  const classes = useStyle({color, fullWidth, textColor, borderBottom});

  return (
    <>
      <div id={id} className={classes.featureTitle}>
        <div>
          {icon && <img src={icon} />}
          {!icon
            ? <h5>{title}</h5>
            : <h6>{title}</h6>
          }
        </div>
      </div>
      {detail &&
        <div className={classes.featureDetail}>
          {detail}
        </div>
      }
    </>
  );
}
