import React, { Fragment } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { YoutubeGridVideo } from './components/YoutubeGridVideo';

const useStyle = makeStyles((theme) => ({
  title: ({ color }) => ({
    maxWidth: 1335,
    margin: '18px auto 0',
    padding: '0 10px',
    '& h5': {
      color: color,
      fontWeight: 400,
      margin: 0,
      fontSize: '1.8rem',
    },
  }),
  buttonsBlock: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  videos: ({ color }) => ({
    backgroundColor: '#fff',
    display: 'flex',
    maxWidth: 1335,
    margin: 'auto',
    padding: '20px 10px',
    gap: 20,
    flexWrap: 'wrap',
    '& div.video-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& a.sw-btn-primary': {
        padding: '8px 20px',
        backgroundColor: 'transparent',
        color,
        border: `1px solid ${color}`
      }
    }
  })
}));

export function TrainingVideos(
  { color, title, details = [] }
) {
  const locale = useSelector((state) => state.setting.locale);
  const classes = useStyle({ color });

  return (
    <>
      <div className={classes.title}>
        <Typography variant="h5">{title}</Typography >
      </div>
      <div className={classes.videos}>
        {details.map((el, i) => {
          if(typeof el.keyNum === 'number') {
            el.keyNum = [el.keyNum];
          }

          return (
            <div className="video-item" key={i}>
              <YoutubeGridVideo
                color={color}
                video={el.video}
              />
              <div className={classes.buttonsBlock}>
                {el.keyNum.map(item => (
                  <a
                    key={item}
                    target="_blank"
                    className="sw-btn-primary mt-3"
                    href={`/${locale}/toolkit/FichaClave${item}.pdf`}
                  >
                    Ficha Clave {item}
                  </a>
                ))}
              </div>
            </div>);
        })}
      </div>
    </>
  );
}
