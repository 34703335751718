import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: ({background}) => ({
    backgroundColor: background,
    '& > div': {
      maxWidth: 1335,
      padding: '1.5rem 10px',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  }),
  fegure: {
    width: '40%',
  },
  details: ({ color }) => ({
    width: '50%',
    '& h4': {
      color,
      fontSize: '1.7rem',
      fontWeight: 500,
    },
  })
}));

export function TrainingBox(
  {color, title, detail, background}
) {
  const classes = useStyle({color, background});

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.fegure}>
          {detail}
        </div>
        <div className={classes.details}>
          <Typography variant="h4">{title}</Typography>
        </div>
      </div>
    </div>
  );
}
