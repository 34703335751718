import React from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";

import logoBertelsmann from "assets/img/logoBertelsmann.png";
import Carousel_Funciones from "./Carousel_Funciones.js";

import Carousel_Elegir from "./Carousel_Elegir.js";
import * as _ from "lodash";
import ReactPlayer from "react-player";

import {getRawMessage, Language} from 'components/Language';
import { useSelector } from "react-redux";

function Ceap() {
  const locale = useSelector((state) => state.setting.locale);

  return (
    <div>
      { localStorage.getItem("xcauth") &&
        localStorage.getItem("xcauth") === "true" 
          ? <NavBarAuth />
          : <NavBar />
      }

      <div >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="bgWhite cornerHomeLeft homeContainer homeContainerXcelence marginCeap"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className=" imgCaruselHomeNoResponsive imgCenter"
          >
            <ReactPlayer
              className="videoCeapHome"
              url={getRawMessage('links.ceap_video')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="itemHomeResp"
          >
            <div className="marginRightHome">
              <div className="h1home padBottomHome lineCeap">
                <Language langKey="ceap.what_is_coordinator" />
              </div>

              <div className="txtHome padBottomHomeMore sNoResponsive">
                <p><Language langKey="ceap.coordinator_paragraph_one" /></p>
                <p><Language langKey="ceap.coordinator_paragraph_two" /></p>
              </div>
            </div>
            <div className="txtHome padBottomHomeMore sResponsive">
              <p><Language langKey="ceap.coordinator_paragraph_one" /></p>
              <p><Language langKey="ceap.coordinator_paragraph_two" /></p>
            </div>
            <ReactPlayer
              className="videoHomeResponsive sizeVideoCap"
              url={getRawMessage('links.ceap_video')}
            />
          </Grid>
        </Grid>

        <div className="bgWhite marginCeap">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="bgGrey cornerHomeRight"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter"
            >
              <div className="marginLeftHome marginRightHome comoRelaciona">
                <div className="h1home padBottomHome alignCenterO lineCeap">
                  <Language langKey="ceap.what_are_ceap_roles" />
                </div>

                <div
                  className="txtHome padBottomHomeMore"
                  style={{ marginTop: 15 }}
                >
                  <Language langKey="ceap.ceap_roles_intro" />:
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter topCarruselClaves"
            >
              <Carousel_Funciones className="comoRelaciona" />
            </Grid>
          </Grid>
        </div>

        <div className="bgGrey">
          <Grid
            container
            spacing={3}
            className=" bgWhite cornerHomeLeft  homeContainer homecontainernobottom"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="h1home padBottomHome comoRelaciona alignCenterO lineCeap">
                <Language langKey="ceap.network" />
              </div>
              <div className="txtHome padBottomHomeMore comoRelaciona">
                <Language langKey="ceap.network_description" />
              </div>
              <div className="comoRelaciona imgCenter">
                <img
                  className="imgHomeComo"
                  src={require(`assets/img/${locale}/ceap/how.png`)}
                  sizes="100vw" />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="bgGrey cornerHomeRight"
            alignItems="center"
          >
             <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter"
            >
              <div className="marginLeftHome marginRightHome comoRelaciona">
                <div className="h1home padBottomHome alignCenterO lineCeap">
                  <Language langKey="ceap.who_choose" />
                </div>

                <div
                  className="txtHome padBottomHomeMore"
                  style={{ marginTop: 15 }}
                >
                  <Language langKey="ceap.like_this" />:
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter topCarruselClaves"
            >
              <Carousel_Elegir className="comoRelaciona" />
            </Grid>
          </Grid>
        </div>

        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            className="bgWhite homeContainer homeContainerLogo"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="subh1home">
                <Language langKey="dashboard.owner" />
              </div>
              <img src={logoBertelsmann} className="logoBertelsHome" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Ceap;
