import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: ({ background }) => ({
    backgroundColor: background,
    padding: '1.5rem 15px',
    '& > div': {
      maxWidth: 1300,
      margin: 'auto',
    },
    '& .sw-btn-primary': {
      textTransform: 'initial',
      paddingLeft: 30,
      paddingRight: 30,
    }
  }),
  imageGrid: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2.5rem',
  },
  details: ({ color }) => ({
    '& h4': {
      color,
      fontSize: '1.7rem',
      fontWeight: 500,
    },
  })
}));

const images = [
  {
    image: 'https://www.fundacionbertelsmann.org/wp-content/uploads/2023/05/2.png',
    link: 'https://www.fundacionbertelsmann.org/publicaciones/el-modelo-de-orientacion-xcelence-10-claves-para-una-orientacion-integral-y-de-calidad/'
  },
  {
    image: 'https://www.fundacionbertelsmann.org/wp-content/uploads/2021/10/guia_padres2_web.png',
    link: 'https://www.fundacionbertelsmann.org/publicaciones/como-orientar-profesionalmente-a-tu-hijo/'
  },
  {
    image: 'https://www.fundacionbertelsmann.org/wp-content/uploads/2023/05/2304-FB-Informe-anual-Xcelence-2022-portada-vertical-para-banner-preview.jpg',
    link: 'https://www.fundacionbertelsmann.org/publicaciones/el-estado-de-la-orientacion-en-espana-segun-el-modelo-xcelence/'
  },
];

const link = 'https://www.fundacionbertelsmann.org/publicaciones/?type=&lang=&category=orientacion-profesional&q=';

export function BannerFooter(
  { color, title, detail, background }
) {
  const classes = useStyle({ color, background });

  return (
    <div className={classes.container}>
      <div>
        <Typography align="center" className="mb-5 mt-3" variant="h4">{title}</Typography>
        <div className={classes.imageGrid}>
          {images.map(item => (
            <a href={item.link} target="_blank">
              <img width={160} src={item.image} />
            </a>
          ))}
        </div>
        <div className="text-center my-5" >
          <a className="sw-btn-primary" href={link} target="_blank">
            Ver más publicaciones de la Fundación
          </a>
        </div>
      </div>
    </div>
  );
}

