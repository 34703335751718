import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import logoBertelsmann from "assets/img/logoBertelsmann.png";
import { Language } from "components/Language.js";
import logoXcelence from "assets/img/awards/xcelence_dark_logo.png";

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'relative',
    padding: '60px 15px',

    '& > .container': {
      display: 'flex',
      padding: '20px 25px',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      borderRadius: 20,

      '& img': {
        height: 30,

        [theme.breakpoints.up('sm')]: {
          height: 45,
        }
      },

      '& .footer-caption': {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'initial'
        }
      },
    }
  },
}));

export function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div className="container">
        <img src={logoXcelence} />
        <div>
          <Typography className="footer-caption" variant="caption"><Language langKey="report_page.support" />: &nbsp;</Typography>
          <img src={logoBertelsmann} />
        </div>
      </div>
    </div>
  );
}
