import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

import standardWheel from "assets/img/ES/wheel_cropped.gif";
import { useSelector } from "react-redux";

const useStyle = makeStyles(theme => ({
  welcomeSeccion: {
    maxWidth: 1315,
    flexWrap: 'wrap',
    margin: '50px auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: 25,
    marginBottom: '2.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  welcomeContainer: {
    width: 'calc(100% - 480px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& > h3': {
      fontWeight: 'bold',
    },
    '&.img': {
      width: 450,
      margin: 'auto',
    }
  },
  shape: {
    position: 'absolute',
    height: 130,
    width: 'calc(50vw - 456px)',
    background: 'var(--corpo-color)',
    left: '100%',
    top: '50%',
    zIndex: -1,
    transform: 'translateY(-50%)',
    '&::before': {
      content: '""',
      height: '100%',
      display: 'block',
      width: 28,
      backgroundColor: '#fff',
      borderRadius: '50%',
      position: 'relative',
      transform: 'translateX(-50%)',
    },
  },
}));

export function TrainingWelcome() {
  const locale = useSelector((state) => state.setting.locale);
  const classes = useStyle();

  return (
    <div style={{overflow: 'hidden'}}>
      <div className={classes.welcomeSeccion}>
        <div className={classes.welcomeContainer}>
          <Typography variant="h4">¿Quieres implementar el modelo Xcelence en tu centro?</Typography>
          <Typography variant="body1" className="mt-3">
            En esta sección encontrarás píldoras de formación que te ayudarán a entender las 10 claves del modelo Xcelence y te aportarán ideas y recursos para aplicar acciones en tu centro.
          </Typography>
        </div>
        <img className={classes.welcomeContainer + ' img'} src={standardWheel} />  

        <div className={classes.shape}></div>
      </div>
    </div>
  );
}
