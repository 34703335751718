import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import stainImage from "assets/img/stain_red.png";

const useStyles = makeStyles(theme => ({
  keysGraphic: {
    backgroundImage: `url(${stainImage})`,
    backgroundPosition: 'left center',
    backgroundSize: '580px',
    backgroundRepeat: 'no-repeat',

    '& > .container': {
      maxWidth: theme.breakpoints.values.md - 300,
      paddingTop: 50,
      paddingBottom: 50,

      [theme.breakpoints.up('sm')]: {
        paddingTop: 90,
        paddingBottom: 90,
      }
    },
  },
}));

export function XcelenceGoal() {
  const classes = useStyles();

  return (
    <div className={classes.keysGraphic}>
      <div className="container">
        <Typography className="mb-4" variant="h5">
          El modelo <mark>Xcelence</mark> proporciona a los centros educativos una <mark>visión innovadora</mark> y estratégica sobre la <mark>orientación</mark> basada en estándares internacionales.
        </Typography>
      </div>
    </div>
  );
}
