import React, { useEffect } from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import logoBertelsmann from "assets/img/logoBertelsmann.png";
import azul from "assets/img/home/azul.png";
import Carousel_Claves from "./Carousel_Claves.js";
import { v1 as uuidv1 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { claveActions } from "actions/claveActions";
import Carousel_Testimonios from "./Carousel_Testimonios.js";
import beneficio1 from "assets/img/icons/carpeta.png";
import beneficio2 from "assets/img/icons/conexion.png";
import beneficio3 from "assets/img/icons/manos.png";
import beneficio4 from "assets/img/icons/personared.png";
import beneficio5 from "assets/img/icons/gorro.png";
import ReactPlayer from "react-player";
import * as _ from "lodash";
import { CentersAffilated } from "./components";
import { Language } from "components/Language.js";

function Xcelence() {
  const dispatch = useDispatch();
  const claves = useSelector((state) => state.claveReducer.claveList);
  const locale = useSelector((state) => state.setting.locale);

  useEffect(() => {
    dispatch(claveActions.getClaves());
  }, [locale]);

  return (
    <div className="fullPage">
      { localStorage.getItem("xcauth") &&
        localStorage.getItem("xcauth") === "true" 
          ? <NavBarAuth />
          : <NavBar />
      }

      <div className="bgGrey">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="bgWhite cornerHomeLeft homeContainer homeContainerXcelence"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className=" imgCaruselHomeNoResponsive imgCenter"
          >
            <img
              className="imgHomeXcelence"
              src={require(`assets/img/${locale}/home/key_wheel.png`)}
              sizes="100vw"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="itemHomeResp"
          >
            <div className="marginRightHome">
              <div className="h1home padBottomHome">
                <Language langKey="xcelence.what_is_it" />
              </div>

              <div className="txtHome padBottomHomeMore sNoResponsive">
                <Language langKey="xcelence.its_paragraph" />
                <ul>
                  <li>
                    <Language langKey="xcelence.its_li_one" />
                  </li>
                  <li>
                    <Language langKey="xcelence.its_li_two" />
                  </li>
                  <li>
                    <Language langKey="xcelence.its_li_three" />
                  </li>
                  <li>
                    <Language langKey="xcelence.its_li_four" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="txtHome padBottomHomeMore sResponsive">
              <Language langKey="xcelence.its_paragraph" />
              <ul>
                <li>
                  <Language langKey="xcelence.its_li_one" />
                </li>
                <li>
                  <Language langKey="xcelence.its_li_two" />
                </li>
                <li>
                  <Language langKey="xcelence.its_li_three" />
                </li>
                <li>
                  <Language langKey="xcelence.its_li_four" />
                </li>
              </ul>
            </div>
            <img
              className="imgHome imgCaruselHomeResponsive marginBottom"
              src={require(`assets/img/${locale}/home/key_wheel.png`)}
              sizes="100vw"
            />
            <div className="btnCenterResponsive">
              <a className="btn btnHome btnFullButton" href="/auth/start">
                <Language langKey="xcelence.start_self_quiz" />
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="bgGrey cornerHomeRight homeContainer homeContainerXcelence"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="itemHomeResp"
            >
              <div className="marginLeftHome">
                <div className="h1home padBottomHome">
                  <Language langKey="xcelence.who_is_it_for" />
                </div>

                <div className="txtHome padBottomHomeMore">
                  <Language langKey="xcelence.for_paragraph" />
                </div>
                <img
                  className="imgHome imgCaruselHomeResponsive marginBottom"
                  src="https://i.imgur.com/arZWAJ3.jpg"
                  sizes="100vw"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter imgCaruselHomeNoResponsive"
            >
              <img
                className="imgHomeXcelence marginLeftFotoXcelence"
                style={{ width: "300px !important" }}
                src="https://i.imgur.com/arZWAJ3.jpg"
                sizes="100vw"
              />
            </Grid>
            <div className="marginRightHome marginGrid">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="paddingRight"
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        className="checkblue"
                      >
                        <img src={azul} />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <div className="subh1home padBottomHome">
                          <Language langKey="xcelence.director_team" />:
                        </div>
                        <div className="txtHome padBottomHomeMore">
                          <Language langKey="xcelence.director_team_describe" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="paddingRight"
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        className="checkblue"
                      >
                        <img src={azul} />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <div className="subh1home padBottomHome">
                          <Language langKey="xcelence.conductor_team" />:
                        </div>
                        <div className="txtHome padBottomHomeMore">
                          <Language langKey="xcelence.conductor_team_describe" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        className="checkblue"
                      >
                        <img src={azul} />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <div className="subh1home padBottomHome">
                          <Language langKey="xcelence.teacher_team" />:
                        </div>
                        <div className="txtHome padBottomHomeMore">
                          <Language langKey="xcelence.teacher_team_describe" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        {/*
        Xcelence
      */}
        <div className="bgGrey">
          <Grid
            container
            spacing={3}
            className=" bgWhite cornerHomeLeft  homeContainer homecontainernobottom"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter"
            >
              <div className="marginLeftHome marginRightHome">
                <div className="h1home padBottomHome">
                  <Language langKey="xcelence.what_mean_keys" />
                </div>

                <div
                  className="txtHome padBottomHomeMore"
                  style={{ marginTop: 15 }}
                >
                  <Language langKey="xcelence.keys_mean" />
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter topCarruselClaves"
            >
              {claves && claves.length > 0 && (
                <Carousel_Claves data={claves ? claves : []} />
              )}
            </Grid>
          </Grid>
        </div>
        {/*
        Recursos
      */}
        <div key={uuidv1()} className="bgWhite">
          <Grid
            container
            spacing={3}
            align="center"
            justifyContent="center"
            className="bgGrey cornerHomeRight homeContainer"
          >
            <div className="marginLeftHome marginRightHome">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="imgCenter"
              >
                <div className="h1home padBottomHome">
                  <Language langKey="xcelence.how_works" />
                </div>
                <div className="txtHome padBottomHomeMore">
                  <Language langKey="xcelence.model_provides" />:
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="paddingRight"
                  >
                    <Grid container className="mPaddingR mPaddingR2">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="subh1home padBottomHome">
                          <Language langKey="xcelence.tools" />
                        </div>
                        <div className="txtHome padBottomHomeMore">
                          <Language langKey="xcelence.tools_description" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="paddingRight"
                  >
                    <Grid container className="mPaddingR mPaddingR2">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="subh1home padBottomHome">
                          <Language langKey="xcelence.diagnosis" />
                        </div>
                        <div className="txtHome padBottomHomeMore">
                          <Language langKey="xcelence.diagnosis_description" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Grid container className="mPaddingR mPaddingR2">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="subh1home padBottomHome">
                          <Language langKey="xcelence.resources" />
                        </div>
                        <div className="txtHome padBottomHomeMore">
                          <Language langKey="xcelence.resources_description" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        <div key={uuidv1()} className="bgGrey">
          <Grid
            container
            spacing={4}
            align="center"
            justifyContent="center"
            className="bgWhite cornerHomeLeft homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter"
            >
              <div className="h1home padBottomHome">
                <Language langKey="xcelence.what_benefits" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              className="imgCenter"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              className="imgCenter"
            >
              <img src={beneficio1} className="beneficio" />
              <div className="txtHome text-start marginGrid2">
                <Language langKey="xcelence.benefit_one" />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              className="imgCenter"
            >
              <img src={beneficio2} className="beneficio" />
              <div className="txtHome text-start marginGrid2 ">
                <Language langKey="xcelence.benefit_two" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              className="imgCenter"
            >
              <img src={beneficio3} className="beneficio" />
              <div className="txtHome text-start marginGrid2 ">
                <Language langKey="xcelence.benefit_three" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              className="imgCenter"
            >
              <img src={beneficio4} className="beneficio" />
              <div className="txtHome text-start marginGrid2 ">
                <Language langKey="xcelence.benefit_four" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              className="imgCenter"
            >
              <img src={beneficio5} className="beneficio" />
              <div className="txtHome text-start marginGrid2 ">
                <Language langKey="xcelence.benefit_five" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              className="imgCenter"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="imgCenter"
            >
              <br />
              <a className="btn btnHome" href="/auth/start">
                <Language langKey="xcelence.self_quiz_center" />
              </a>
            </Grid>
          </Grid>
        </div>
        {/*
        Practicas
      */}
        <div key={uuidv1()} className="bgWhite">
          <Grid
            container
            spacing={3}
            align="center"
            justifyContent="center"
            className="bgGrey homeContainer cornerHomeRight"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="h1home padBottomHome">
                <Language langKey="xcelence.which_origin_is" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="marginLeftHome ">
                <div className="txtHome txtHomeStrong padBottomHome">
                  <Language langKey="xcelence.inspired_from" />&nbsp;
                  <a
                    className="txtHomelink"
                    href="https://www.gatsby.org.uk/education/focus-areas/good-career-guidance"
                  >
                    Good Career Guidance
                  </a>,&nbsp;
                  <Language langKey="xcelence.good_career" />
                </div>
                <div style={{ marginTop: 25 }}>
                  <ReactPlayer
                    width="100%"
                    height="360"
                    url="https://www.youtube.com/watch?v=RbBtKXSxN7o"
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="itemHomeResp"
            >
              <div className="marginRightHome">
                <div className="txtHome txtHomeStrong padBottomHomeMore">
                  <Language langKey="xcelence.based_countries" /> 
                  <br />
                  <br />
                  <div className="txtHome txtHomeStrong">
                    <Language langKey="xcelence.phases" /> 
                  </div>
                  <div className="nomobile">
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="padd10"
                      >
                        <div className="borderPuntua">
                          <div className="txtHome txtYear">2017</div>
                          <div className="txtHomeLittle txtgrey">
                            <Language langKey="xcelence.phase_2017" /> 
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="  "
                      >
                        <div>
                          <div className="txtHome txtYear">2018</div>
                          <div className="txtHomeLittle txtgrey">
                            <Language langKey="xcelence.phase_2018" /> 
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="padd10  marg10"
                      >
                        <div>
                          <div className="borderPuntuaArriba moveBorder" />
                          <div className="borderPuntua">
                            <div className="txtHome txtYear marg10">2019</div>
                            <div className="txtHomeLittle txtgrey">
                              <Language langKey="xcelence.phase_2019" /> 
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className=" marg10"
                      >
                        <div className="borderPuntuaArriba">
                          <div className="txtHome txtYear marg10">2020</div>
                          <div className="txtHomeLittle txtgrey">
                            <Language langKey="xcelence.phase_2020" /> 
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="yesmobile">
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="padd10"
                      >
                        <div className="txtHome txtYear">2017</div>
                        <div className="txtHomeLittle txtgrey">
                          <Language langKey="xcelence.phase_2017" /> 
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="marg15"
                      >
                        <div className="txtHome txtYear">2018</div>
                        <div className="txtHomeLittle txtgrey">
                          <Language langKey="xcelence.phase_2018" /> 
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="padd10  marg10"
                      >
                        <div className="txtHome txtYear marg10">2019</div>
                        <div className="txtHomeLittle txtgrey">
                          <Language langKey="xcelence.phase_2019" /> 
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className=" marg10"
                      >
                        <div className="txtHome txtYear marg10">2020</div>
                        <div className="txtHomeLittle txtgrey">
                          <Language langKey="xcelence.phase_2020" /> 
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {/*
         * Centros que lo han hecho
         */}
        <div key={uuidv1()} className="bgGrey">
          <Grid
            container
            spacing={3}
            className="bgWhite cornerHomeLeft homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="alignCenter"
            >
              <div className="h1home padBottomHome">
                <Language langKey="dashboard.impact" />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="alignCenter"
            >
              <div className="marginRightHome marginLeftHome">
                <Carousel_Testimonios />
              </div>
            </Grid>
          </Grid>
        </div>
        <CentersAffilated />
        {/* <NewsLetter /> */}
        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            className="bgWhite homeContainer homeContainerLogo"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="subh1home">
                <Language langKey="dashboard.owner" />
              </div>
              <img src={logoBertelsmann} className="logoBertelsHome" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Xcelence;
