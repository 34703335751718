import React from "react";
import { makeStyles } from "@material-ui/core";
import { Language } from "components/Language";
import { AwardsJury } from "./components/AwardsJury";

const useStyle = makeStyles((theme) => ({
  awardJury: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '20px 0',
    '& > h4': {
      color: theme.palette.primary.main,
      borderBottom: '2px solid #e6e6e6',
      margin: 0,
      padding: '0 0 20px',
      fontWeight: 'bold',
      fontSize: '1.3rem',
    },
    '& > p': {
      maxWidth: 700,
      margin: '20px auto 0',
      fontSize: '1rem',
    }
  },
  categoriesGrid: {
    maxWidth: 900,
    margin: '24px auto 16px',
    display: 'flex',
    gap: 25,
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      gap: 0,
      paddingLeft: 16,
      paddingRight: 16,
    }
  }
}));

export function AwardsJuries() {
  const classes = useStyle();

return (
    <div className={classes.awardJury}>
      <h4><Language langKey="award_page.jury_title" /></h4>
      <div className={classes.categoriesGrid}>
        <AwardsJury
          title={<Language langKey="award_page.job_title" />}
          color="#de6c6c"
          detail={
            <ul>
              <li><Language parse langKey="award_page.jury_connexion.line_one" /></li>
              <li><Language parse langKey="award_page.jury_connexion.line_two" /></li>
              <li><Language parse langKey="award_page.jury_connexion.line_three" /></li>
              <li><Language parse langKey="award_page.jury_connexion.line_four" /></li>
            </ul>
          }
        />
        <AwardsJury
          title={<Language langKey="award_page.road_title" />}
          color="#63b26b"
          detail={
            <ul>
              <li><Language parse langKey="award_page.jury_orientation.line_one" /></li>
              <li><Language parse langKey="award_page.jury_orientation.line_two" /></li>
              <li><Language parse langKey="award_page.jury_orientation.line_three" /></li>
              <li><Language parse langKey="award_page.jury_orientation.line_four" /></li>
            </ul>
          }
        />
        <AwardsJury
          title={<Language langKey="award_page.information_title" />}
          color="#cd7e47"
          detail={
            <ul>
              <li><Language parse langKey="award_page.jury_information.line_one" /></li>
              <li><Language parse langKey="award_page.jury_information.line_two" /></li>
              <li><Language parse langKey="award_page.jury_information.line_three" /></li>
            </ul>
          }
        />
        <AwardsJury
          title={<Language langKey="award_page.connection_title" />}
          color="#996ba3"
          detail={
            <ul>
              <li><Language parse langKey="award_page.jury_binding.line_one" /></li>
              <li><Language parse langKey="award_page.jury_binding.line_two" /></li>
              <li><Language parse langKey="award_page.jury_binding.line_three" /></li>
              <li><Language parse langKey="award_page.jury_binding.line_four" /></li>
            </ul>
          }
        />
      </div>
    </div>
  );
}
