import { makeStyles } from "@material-ui/core";
import raiseCover from "assets/img/home/practicas.png";
import standardWheel from "assets/img/ES/wheel.gif";

export const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    backgroundColor: '#F5F5F5',
    '& h1': {
      fontSize: '4rem',
      fontWeight: 'bold',
      margin: 0,
      textAlign: 'center',
      color: 'inherit',
      lineHeight: 1.03,
    },
    '& h2': {
      fontSize: '3.2rem',
      margin: 0,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'inherit',
    },
    '& h3': {
      fontSize: '2.6rem',
      margin: 0,
      fontWeight: 'bold',
      color: 'inherit',
    },
    '& h4': {
      fontSize: '2.3rem',
      margin: 0,
      color: 'inherit',
      lineHeight: 1.13,
    },
    '& h5': {
      fontSize: '1.4rem',
      margin: 0,
      lineHeight: 1.25,
      color: 'inherit',
    },
    '& h6, & .h6': {
      fontSize: '1.3rem',
      margin: 0,
      textTransform: 'none',
      lineHeight: 1.25,
    },
    '& mark': {
      padding: 0,
      color: 'var(--corpo-color)',
      backgroundColor: 'transparent',
    },
    '& a:hover': {
      color: '#fff',
    },
    '& .caption': {
      color: '#575756',
    },
    '& .disabled': {
      color: '#a2a2a2',
    },

    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.5rem',
      },
      '& h2': {
        fontSize: '2.2rem',
      },
      '& h3': {
        fontSize: '1.9rem',
      },
      '& h4': {
        fontSize: '1.6rem',
      },
    },
    '& .container': {
      maxWidth: theme.breakpoints.values.md + 100,
      margin: 'auto',
      padding: '35px 12px',
      [theme.breakpoints.up('sm')]: {
        padding: '70px 12px',
      }
    }
  },
  crossLongLive: {
    borderBottomRightRadius: 40,
    backgroundColor: '#fff',
    position: 'relative',

    '&::before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      backgroundColor: '#f2f2f2',
      zIndex: -1,
    },

    '& > div': {
      maxWidth: theme.breakpoints.values.md,
      margin: 'auto',
      padding: '60px 10px',
      position: 'relative',
      '&  h5': {
        textAlign: 'center',
      },
      '&  h6': {
        textAlign: 'start',
      },
      '&::before': {
        content: "'···········'",
        position: 'absolute',
        left: 0,
        top: 0,
        fontSize: 25,
        lineHeight: 0.5,
        letterSpacing: 2,
        color: 'var(--corpo-color)',
      },
    }
  },
  xcelenceModel: {
    borderBottomLeftRadius: 40,
    backgroundColor: '#f2f2f2',
    position: 'relative',

    '&::before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      backgroundColor: '#fff',
      zIndex: -1,
    },

    '& > div': {
      maxWidth: theme.breakpoints.values.md,
      margin: 'auto',
      padding: '60px 10px',
    }
  },
  markDifference: {
    backgroundColor: '#fff',

    '& > div': {
      maxWidth: theme.breakpoints.values.md,
      display: 'flex',
      rowGap: 25,
      alignItems: 'center',
      margin: 'auto',
      padding: '45px 10px',
      flexWrap: 'wrap-reverse',
      justifyContent: 'center',
      position: 'relative',

      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },

      '&::before': {
        content: "'···········\\A···········'",
        position: 'absolute',
        right: 0,
        top: 10,
        fontSize: 25,
        lineHeight: 0.4,
        letterSpacing: 2,
        color: 'var(--corpo-color)',
        whiteSpace: 'pre-wrap',
      },


      '& > div': {
        backgroundImage: `url(${standardWheel})`,
        backgroundPosition: '51% 51%',
        backgroundSize: '132% auto',
      },
      
      '& img': {
        width: '100%',
        maxWidth: 450,
        opacity: 0,
      },
      '& h5': {
        width: 465,
      },
    }
  },
  leaderPercentage: {
    color: '#fff',
    backgroundImage: `linear-gradient(#dc074077, #dc074077), url(${raiseCover})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',

    '& > div': {
      maxWidth: theme.breakpoints.values.md,
      margin: 'auto',
      padding: '60px 15px',
      '& > div > h6': {
        maxWidth: 510,
        color: '#fff',
        textAlign: 'start',
      },
      '& > div > h1': {
        fontSize: '7rem',
      },
      '& div': {
        marginTop: 25,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 25
      },
    }
  },
  getInsparation: {
    backgroundColor: '#fff',
    borderBottomRightRadius: 40,
    position: 'relative',

    '&::before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      backgroundColor: '#f2f2f2',
      zIndex: -1,
    },
    '& > div': {
      maxWidth: theme.breakpoints.values.md,
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '50px 15px',
      flexDirection: 'column',
      gap: 25,

      '& > a': {
        width: 210,
      }
    }
  },
}));
