import React from "react";
import { BottomNavigation, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useStatus } from "utils/custon-hooks";
import { useSelector } from "react-redux";
import { Language } from "components/Language";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FileField from "./FileField";
import ImageField from "./ImageField";
import { Link } from "react-router-dom";
import * as yup from 'yup';

const initResource = {
  title: '',
  description: '',
  authors: [],
  authorLinks: [],
  keys: [],
  courses: [],
  perspective: '',
}
const initContact = {
  name: '',
  lastName: '',
  email: '',
  mobile: '',
  position: '',
  ownership: '',
  ccaa: null,
  province: null,
  municipality: null,
}
const initBestPractice = {
  keydatas: '',
  targets: '',
  results: '',
  conclusions: '',
  recomendations: '',
  document: '',
  centerLogo: null,
}

yup.setLocale({
  mixed: {
    required: '${path}_Campo obligatorio',
  },
  string: {
    min: '${path}_Como mínimo se requiere ${min} caracteres',
    max: '${path}_Como maximo es permitido ${max} caracteres.',
    email: '${path}_Correo electrónico inválido.'
  },
  array: {
    min: '${path}_Como mínimo debe introducir ${min} elemento/s',
    max: '${path}_Como maximo es permitido ${max} elemento/s.',
  },
});

let resourceSchema = yup.object().shape({
  title: yup.string().required().min(3).max(100),
  keydatas: yup.string().required().min(3).max(400),
  description: yup.string().required().min(3).max(1300),
  targets: yup.string().required().min(3).max(500),
  results: yup.string().required().min(3).max(600),
  recomendations: yup.string().required().min(3).max(400),
  conclusions: yup.string().required().min(3).max(600),
  authors: yup.array().min(1),
  authorLinks: yup.array().min(1),
  perspective: yup.string().required(),
  keys: yup.array().required().min(1),
  courses: yup.array().required().min(1),
  ownership: yup.string().required(),
  ccaa: yup.object().required(),
  name: yup.string().required().max(100),
  position: yup.string().required().min(3).max(100),
  email: yup.string().required().email(),
});

const ownerships = [
  'public',
  'private',
  'concerted',
];
let fprovinces = [], fmunicipalities = [];
export function BestPracticeForm(props) {
  const [bestPractice, setBestPractice] = useStatus({ ...initBestPractice });
  const [contact, setContact] = useStatus({ ...initContact });
  const [resource, setResource] = useStatus({ ...initResource });
  const [errors, setErrors] = useStatus({});
  const locale = useSelector((state) => state.setting.locale);

  const keys = useSelector((state) => state.claveReducer.claveList);
  const courses = useSelector((state) => state.centroReducer.getCursos);
  const perspectives = useSelector(
    (state) => state.recursosReducer.perspectivasList
  );
  const {ccaa, provinces, municipalities} = useSelector(
    state => state.reportsReducer.dashboardFilterAssets
  );

  const handleChange = (event) => {
    const name = event.target.name;
    let setter = setResource;

    if(bestPractice[name] !== undefined) {
      setter = setBestPractice;
    } else if(contact[name] !== undefined) {
      setter = setContact;
    } 

    setter({
      [name]: event.target.value
    })

    setErrors({ [name]: undefined });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    try {
      let _contact = {
        ...contact,
        ccaa: contact.ccaa?.id,
        ccaa_label: contact.ccaa?.nombre,
        province: contact.province?.id,
        province_label: contact.province?.provincia,
        municipality: contact.municipality?.id,
        municipality_label: contact.municipality?.nombre,
      }

      resourceSchema.validateSync(
        { ...resource, ...bestPractice, ...contact },
        { abortEarly: false }
      );

      props.onSubmit(resource, {...bestPractice}, _contact);
    } catch(error) {
      const invalids = {};

      for(const item of error.errors) {
        const [field, msg] = item.split('_');

        invalids[field] = msg;
      }  

      console.log(invalids);
      setErrors(invalids);
    }
  }

  const onChangeField = (name, value) => {
    if(bestPractice[name] !== undefined) {
      setBestPractice({ [name]: value});
    } else  {
      setResource({ [name]: value});
    } 

    setErrors({ [name]: undefined });
  }

  const onChangeAutocomplete = (name, data) => {
    let next = {[name]: data};

    if(name === 'province') {
      fmunicipalities = municipalities.filter(m => m.id_provincia == data.id);

      next = { ...next, municipality: null};
    } else if(name === 'ccaa') {
      fprovinces = provinces.filter(p => p.comunidad_id == data.id)
      fmunicipalities = [];

      next = { ...next, municipality: null, province: null};
    }
    
    setContact(next);
    setErrors({ [name]: undefined });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} className="my-1 ml-1">
          <Typography variant="subtitle1">
            Datos Buenas Prácticas
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            value={resource.title}
            name='title'
            inputProps={{ maxLength: 100 }}
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.title" />}
            error={Boolean(errors.title)}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            multiline
            minRows={2}
            value={bestPractice.keydatas}
            name='keydatas'
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.key_datas" />}
            error={Boolean(errors.keydatas)}
            helperText={errors.keydatas}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={2}
            size="small"
            value={resource.description}
            name='description'
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.description" />}
            error={Boolean(errors.description)}
            helperText={errors.description}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={2}
            size="small"
            value={bestPractice.targets}
            name='targets'
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.targets" />}
            error={Boolean(errors.targets)}
            helperText={errors.targets}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={2}
            size="small"
            value={bestPractice.results}
            name='results'
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.results" />}
            error={Boolean(errors.results)}
            helperText={errors.results}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            size="small"
            minRows={2}
            value={bestPractice.recomendations}
            name='recomendations'
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.recomendations" />}
            error={Boolean(errors.recomendations)}
            helperText={errors.recomendations}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            size="small"
            minRows={2}
            value={bestPractice.conclusions}
            name='conclusions'
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.conclusions" />}
            error={Boolean(errors.conclusions)}
            helperText={errors.conclusions}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            freeSolo
            size="small"
            options={[]}
            onChange={(_, data) => onChangeField('authors', data)}
            value={resource.authors}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={<Language langKey="assets.form.authors" />}
                placeholder="Silvia Saucedo"
                error={Boolean(errors.authors)}
                helperText={errors.authors ?? "`Enter` para ingresar varios valores."}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            freeSolo
            size="small"
            options={[]}
            value={resource.authorLinks}
            onChange={(_, data) => onChangeField('authorLinks', data)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={<Language langKey="assets.form.author_links" />}
                error={Boolean(errors.authorLinks)}
                helperText={errors.authorLinks ?? "`Enter` para ingresar varios valores."}
                placeholder="https://saucendogroup.com"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            error={Boolean(errors.perspective)}
          >
            <InputLabel><Language langKey="perspective" /></InputLabel>
            <Select
              value={resource.perspective}
              onChange={handleChange}
              name='perspective'
              label={<Language langKey="perspective" />}
            >
              {perspectives?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors.perspective && (
              <FormHelperText error>{errors.perspective}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            error={Boolean(errors.keys)}
          >
            <InputLabel>
              <Language langKey="keys_single" />
            </InputLabel>
            <Select
              value={resource.keys}
              onChange={handleChange}
              multiple
              name='keys'
              label={<Language langKey="keys_single" />}
            >
              {keys?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {`${item.num}. ${item.titulo}`}
                </MenuItem>
              ))}
            </Select>
            {errors.keys && (
              <FormHelperText error>{errors.keys}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            error={Boolean(errors.courses)}
          >
            <InputLabel>
              <Language langKey="assets.form.study_grade" />
            </InputLabel>
            <Select
              value={resource.courses}
              onChange={handleChange}
              multiple
              name='courses'
              label={<Language langKey="assets.form.study_grade" />}
            >
              {courses?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.curso}
                </MenuItem>
              ))}
            </Select>
            {errors.courses && (
              <FormHelperText>{errors.courses}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FileField
            fullWidth
            label={<Language langKey="assets.form.moreinfo" />}
            name="document"
            setFieldValue={onChangeField}
            value={bestPractice.document}
          />
        </Grid>

        <Grid item xs={12} className="my-1 ml-1">
          <Typography variant="subtitle1">
            Datos del centro
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ImageField
            value={bestPractice.centerLogo}
            name="centerLogo"
            helper="Logo centro"
            setFieldValue={onChangeField}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            error={Boolean(errors.ownership)}
          >
            <InputLabel>
              <Language langKey="ownership" />
            </InputLabel>
            <Select
              value={contact.ownership}
              onChange={handleChange}
              name='ownership'
              label={<Language langKey="ownership" />}
            >
              {ownerships?.map((item) => (
                <MenuItem key={item} value={item.toUpperCase()}>
                  <Language langKey={item} />
                </MenuItem>
              ))}
            </Select>
            {errors.ownership && (
              <FormHelperText>{errors.ownership}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            size="small"
            onChange={(_, data) => onChangeAutocomplete('ccaa', data)}
            value={contact.ccaa}
            options={ccaa}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={<Language langKey="ccaa" />}
                error={Boolean(errors.ccaa)}
                helperText={errors.ccaa}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            size="small"
            onChange={(_, data) => onChangeAutocomplete('province', data)}
            value={contact.province}
            options={fprovinces}
            getOptionLabel={(option) => option.provincia}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={<Language langKey="provice" />}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            size="small"
            options={fmunicipalities}
            onChange={(_, data) => onChangeAutocomplete('municipality', data)}
            value={contact.municipality}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={<Language langKey="municipality" />}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} className="my-1 ml-1">
          <Typography variant="subtitle1">
            Datos de contacto
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={contact.name}
            name='name'
            size="small"
            onChange={handleChange}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            label={<Language langKey="assets.form.name" />}
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={contact.position}
            name="position"
            size="small"
            inputProps={{ maxLength: 100 }}
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.position" />}
            error={Boolean(errors.position)}
            helperText={errors.position}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={contact.email}
            name="email"
            size="small"
            inputProps={{ maxLength: 100 }}
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.email" />}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={contact.mobile}
            name="mobile"
            size="small"
            inputProps={{ maxLength: 20 }}
            onChange={handleChange}
            variant="outlined"
            label={<Language langKey="assets.form.cellphone" />}
          />
        </Grid>

        <Grid
          item
          container
          spacing={2}
          className="mt-3"
        >
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="outlined"
              color="inherit"
              type="button"
              component={Link}
              to={{ pathname: `/${locale.toLowerCase()}/recursos` }}
            >
              <Language langKey="assets.form.back" />
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
            >
              <Language langKey="assets.form.send" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
