import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, useMediaQuery } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyle = makeStyles((theme) => ({
  categoryJury: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  featureTitle: ({color}) => ({
    width: '190px',
    padding: 12,
    borderWidth: 0,
    borderRightWidth: 2,
    borderStyle: 'solid',
    borderColor: color,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      color,
      margin: 0,
      fontSize: '1.07rem',
      textAlign: 'start',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRightWidth: 0,
      borderBottomWidth: 2,
      boxShadow: 'none',
      padding: 0,
      '& svg': {
        fill: color
      }
    },
  }),
  expanded: {
    margin: '0 !important',
  },
  featureDetail: {
    flexGrow: 1,
    backgroundColor: '#fff',
    padding: '12px 10px',
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    '& li': {
      textAlign: 'start',
      paddingLeft: 12,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    }
  }
}));

export function AwardsJury(
  {color, title, detail}
) {
  const classes = useStyle({color});
  const matches = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [expanded, setExpanted] = useState(false);

  return matches
    ? (
      <Accordion classes={{expanded: classes.expanded}} onChange={setExpanted.bind(null, !expanded)} elevation={0}>
        <AccordionSummary
          expandIcon={expanded ? <RemoveIcon fontSize="large" /> : <AddIcon fontSize="large" />}
          classes={{root: classes.featureTitle }}
        >
          <h6>{title}</h6>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.featureDetail}}>
          {detail}
        </AccordionDetails>
      </Accordion>
    )
    : (
      <div className={classes.categoryJury}>
        <div className={classes.featureTitle}>
          <h6>{title}</h6>
        </div>
        <div className={classes.featureDetail}>
          {detail}
        </div>
      </div>
    );
}
