/* eslint-disable react/prop-types */
import React from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { NavLink, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {Language} from 'components/Language';

const RegisterOkOtros = (props) => {
  const { t, i18n } = props;
  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="signup.thanks" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language parse  langKey="signup.sucess_teacher" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <NavLink to="/user/dashboard" className="btn btnHome">
              <Language langKey="signup.go_home" />
            </NavLink>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default RegisterOkOtros;
