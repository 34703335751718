import React from "react";
import { Box, Grid, Typography, makeStyles, requirePropFactory } from "@material-ui/core";
import { KeyGrowing } from './KeyGrowing';
import { Legend } from './Legend';

import awarded from 'assets/img/awards/awarded.png';
import comunication from 'assets/img/awards/comunication.png';
import interConnection from 'assets/img/awards/inter-connection.png';
import road from 'assets/img/awards/road.png';
import family from 'assets/img/awards/family.png';
import connection from 'assets/img/awards/connection.png';
import employ from 'assets/img/awards/employ.png';
import job from 'assets/img/awards/job.png';
import grow from 'assets/img/awards/grow.png';
import information from 'assets/img/awards/information.png';
import { Language, getRawMessage } from "components/Language";

const useStyles = makeStyles(theme => ({
  keysGraphic: {
    borderBottomLeftRadius: 40,
    position: 'relative',

    '&::before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      backgroundColor: '#fff',
      zIndex: -1,
    },

    '& > .container': {
      flexDirection: 'column',
      gap: 25,
    }
  },
}));

const keys = [
  {
    color: '#2758AD',
    icon: awarded,
    description: getRawMessage('report_page.key_one_title'),
    first:  46.2,
    second: 66,
    third: 68.4,
  },
  {
    color: '#2287C8',
    icon: comunication,
    description: getRawMessage('report_page.key_two_title'),
    first:  35.4,
    second: 59,
    third: 60.2,
  },
  {
    color: '#3EAC5C',
    icon: interConnection,
    description: getRawMessage('report_page.key_three_title'),
    first:  47.3,
    second: 55.9,
    third: 62.4,
  },
  {
    color: '#63B670',
    icon: road,
    description: getRawMessage('report_page.key_four_title'),
    first:  64.2,
    second: 69.2,
    third: 74.2,
  },
  {
    color: '#81C187',
    icon: family,
    description: getRawMessage('report_page.key_five_title'),
    first:  47,
    second: 56.6,
    third: 65.9,
  },
  {
    color: '#A868A7',
    icon: connection,
    description: getRawMessage('report_page.key_six_title'),
    first:  39,
    second: 49.4,
    third: 55,
  },
  {
    color: '#E5314B',
    icon: employ,
    description: getRawMessage('report_page.key_serven_title'),
    first:  31.6,
    second: 41.8,
    third: 51.2,
  },
  {
    color: '#E9565E',
    icon: job,
    description: getRawMessage('report_page.key_eight_title'),
    first:  27,
    second: 35.3,
    third: 43.6,
  },
  {
    color: '#ED7672',
    icon: grow,
    description: getRawMessage('report_page.key_nine_title'),
    first:  21.5,
    second: 31.5,
    third: 41.5,
  },
  {
    color: '#EF8025',
    icon: information,
    description: getRawMessage('report_page.key_ten_title'),
    first:  72.5,
    second: 78,
    third: 83.5,
  },
]

export function KeyResultGraphic() {
  const classes = useStyles();

  return (
    <div className={classes.keysGraphic}>
      <div className="container">
        <Typography variant="h5">
          <Language langKey="report_page.graphic" />&nbsp;
        </Typography>

        <Typography variant="body2" className="caption mb-4 mt-2">
          <Language langKey="report_page.graphic_hightlight" />
        </Typography>
        <Box display="flex" flexDirection="column" gridGap={10}>
          {keys.map((item) => 
            <KeyGrowing {...item} />
          )}
        </Box>
      </div>
    </div>
  );
}
