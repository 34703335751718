import React, { useEffect, useState } from "react";
import { Paper, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import { LoadingOverlay } from "components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../NavBar/NavBar.js";
import NavBarAuth from "../../NavBar/NavBarAuth.js";
import { claveActions } from "actions/claveActions";
import { centroActions } from "actions/centroActions";
import { recursosActions } from "actions/recursosActions";
import { BestPracticeForm } from "./components/BestPracticeForm";
import { fetchCenterDashboardFilter } from "actions/reports.action";
import { snackbarSetting } from "actions/settings.action";
import { getRawMessage } from "components/Language";
import { useHistory } from "react-router-dom";

const outerTheme = createTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: {
      main: '#dc0740',
    },
    secondary: {
      main: '#fff',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: 15,
        '& input': {
          paddingTop: 12,
          paddingBottom: 12,
        },
        '&[class*="MuiAutocomplete-inputRoot"] > input': {
          paddingTop: '5.5px !important',
          paddingBottom: '5.5px !important',
        },
        '::placeholder': {
          fontSize: 15,
        }
      },
    },
    MuiSelect: {
      root: {
        paddingTop: 12,
        paddingBottom: 12,
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 15,
      },
    }
  }
});

export function BestPracticeView(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const locale = useSelector((state) => state.setting.locale);
  const history = useHistory();

  useEffect(() => {
    initialRequest();
  }, [locale]);

  const initialRequest = async () => {
    setLoading(true);

    try {
      dispatch(recursosActions.getPerspectivas());
      dispatch(claveActions.getClaves());
      dispatch(centroActions.getCursos());

      await new Promise((resolve, reject) => (
        dispatch(fetchCenterDashboardFilter({ reject, resolve }))
      ));
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  const handleSubmit = async (resource, bestPractice, contact) => {
    console.log(resource, bestPractice, contact);

    try {
      setLoading(true);

      await new Promise((resolve, reject) => (
        dispatch(recursosActions.createBestPractice({
          resource,
          bestPractice,
          contact,
          resolve,
          reject,
        }))
      ));

      dispatch(snackbarSetting({
        message: getRawMessage('assets.form.success')
      }));

      history.push(`/${locale}/recursos`);
    } catch (error) {
      console.log(error);

      dispatch(snackbarSetting({
        severity: 'error',
        message: getRawMessage('assets.form.error')
      }));

      setLoading(false);
    }
  }

  return (
    <div style={{ backgroundColor: '#f4f5f7' }}>
      <ThemeProvider theme={outerTheme}>
        {localStorage.getItem("xcauth") === "true"
          ? <NavBarAuth />
          : <NavBar />
        }
        <Paper style={{ maxWidth: 900, margin: '32px auto', padding: 32, position: 'relative' }}>
          <LoadingOverlay isLoading={loading} />
          <Typography variant="h4" className="mb-4">
            ¡Envíanos las buenas prácticas de orientación de tu centro educativo!
            {/* <Language langKey="assets.form.form_title" /> */}
          </Typography>
          <BestPracticeForm onSubmit={handleSubmit} />
        </Paper>
      </ThemeProvider>
    </div>
  );
}
