import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import UserStartPage from "views/auth/UserStartPage";
import Resultado from "views/auth/Resultado";
import Informe from "views/auth/informe/Informe";
import Cuestionario from "views/auth/Cuestionario";
import DescargarPreguntasClave from "views/auth/DescargarPreguntasClave";
import DownloadMapa from "views/auth/DownloadMapa";
import InvitaColaboradores from "views/auth/InvitaColaboradores";
import Historico from "views/auth/Historico";
import Centro from "views/auth/Centro";
import Datos from "views/auth/Datos";
import DashboardPage from "views/start/Dashboard.js";
import ModelPage from "views/start/Xcelence.js";
import RegisterPage from "views/start/Register.js";
import DeleteOk from "views/start/DeleteOk.js";
import TraspasoOk from "views/start/TraspasoOK.js";
import Login from "views/start/Login.js";
import ResetPage from "views/start/Reset.js";
import RegisterInvestigador from "views/start/RegisterInvestigador.js";
import RegisterDirectorPage from "views/start/RegisterDirector.js";
import RegistroCoordinadorContainer from "views/start/RegistroCoordinador/RegistroCoordinadorContainer.js";
import RegisterOk from "views/start/RegisterOk.js";
import Contacto from "views/start/Contacto.js";
import Condiciones from "views/start/Condiciones.js";
import Practicas from "views/start/Practicas.js";
import { Training } from "views/start/Training/Training.js";
import viewToolkit from "views/start/viewToolkit.js";
import PracticaView from "views/start/PracticaView.js";
import ShowRecurso from "views/recursos/components/ShowRecurso.js";
import RegistroOtros from "views/start/RegisterOtros.js";
import DashboardMessage from "views/start/DashboardMessage.js";
import RegisterOkInvestigador from "views/start/RegisterOkInvestigador.js";
import RegisterOkOtros from "views/start/RegisterOkOtros.js";
import Ceap from "views/start/Ceap.js";
import Recover from "views/start/Recover.js";
import Newsletter from "views/start/Newsletter.js";
import messageOk from "views/start/messageOk.js";
import PrivacidadPage from "views/start/Privacidad.js";
import Cookies from "views/start/Cookies.js";
import ListRecursos from "views/start/Resources/ListRecursos.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import RTLPage from "views/RTLPage/RTLPage.js";
import ViewRecurso from "views/start/ViewRecurso.js";
import PreguntasContainer from "views/auth/PreguntasCentro/Containers/PreguntasContainer.js";
import PreguntasContestarContainer from "views/auth/PreguntasCentro/Containers/PreguntasContestarContainer.js";
import { ResultReport } from "views/researcher/ResultReport";
import { KeyReport } from "views/researcher/KeyReport";

const dashboardRoutes = [
  {
    path: "/ceap",
    name: "Ceap",
    icon: Ceap,
    component: Ceap,
    layout: "/user",
  },

  {
    path: "/success",
    name: "DashboardMessage",
    icon: DashboardMessage,
    component: DashboardMessage,
    layout: "/user",
  },

  {
    path: "/message",
    name: "messageOk",
    icon: messageOk,
    component: messageOk,
    layout: "/user",
  },
  {
    path: "/condiciones",
    name: "Condiciones",
    icon: Condiciones,
    component: Condiciones,
    layout: "/user",
  },
  {
    path: "/cookies",
    name: "Cookies",
    icon: Cookies,
    component: Cookies,
    layout: "/user",
  },
  {
    path: "/practicas",
    name: "Practicas",
    icon: Practicas,
    component: Practicas,
    layout: "/user",
  },
  {
    path: "/diezClaves",
    name: "diezClaves",
    component: Training,
    layout: "/user",
  },
  {
    path: "/viewToolkit/:id",
    name: "viewToolkit",
    icon: viewToolkit,
    component: viewToolkit,
    layout: "/user",
  },
  {
    path: "/practica/view",
    name: "PracticaView",
    icon: PracticaView,
    component: PracticaView,
    layout: "/user",
  },

  {
    path: "/delete",
    name: "DeleteOk",
    icon: Dashboard,
    component: DeleteOk,
    layout: "/user",
  },
  {
    path: "/traspaso",
    name: "TraspasoOk",
    icon: Dashboard,
    component: TraspasoOk,
    layout: "/user",
  },
  
  {
    path: "/mapainv",
    name: "DownloadMapa",
    icon: Resultado,
    component: DownloadMapa,
    layout: "/none",
  },
  {
    path: "/informe/:id",
    name: "Informe",
    icon: Informe,
    component: Informe,
    layout: "/auth",
  },
  {
    path: "/resultado",
    name: "Resultado",
    icon: Resultado,
    component: Resultado,
    layout: "/auth",
  },
  {
    path: "/resultado-claves",
    name: "Resultado Claves",
    component: KeyReport,
    layout: "/auth",
  },
  {
    path: "/resultados",
    name: "Resultado Investigador",
    component: ResultReport,
    layout: "/auth",
  },
  {
    path: "/viewrecurso/:id",
    name: "ViewRecurso",
    icon: ViewRecurso,
    component: ViewRecurso,
    layout: "/user",
  },

  {
    path: "/showrecurso",
    name: "showrecu",
    icon: Resultado,
    component: ShowRecurso,
    layout: "/user",
  },
  {
    path: "/datos",
    name: "Datos",
    icon: Dashboard,
    component: Datos,
    layout: "/auth",
  },
  {
    path: "/centro",
    name: "Centro",
    icon: Dashboard,
    component: Centro,
    layout: "/auth",
  },
  {
    path: "/cuestionario",
    name: "Cuestionario",
    icon: Dashboard,
    component: Cuestionario,
    layout: "/none",
  },
  
  {
    path: "/historico",
    name: "Historico",
    icon: Dashboard,
    component: Historico,
    layout: "/auth",
  },
  {
    path: "/descargar/:clave",
    name: "RegistroCoordinadorCompletar",
    icon: Dashboard,
    component: DescargarPreguntasClave,
    layout: "/auth",
  },
  {
    path: "/invita",
    name: "InvitaColaboradores",
    icon: Dashboard,
    component: InvitaColaboradores,
    layout: "/auth",
  },
  {
    path: "/start",
    name: "UserStartPage",
    icon: Dashboard,
    component: UserStartPage,
    layout: "/auth",
  },
  {
    path: "/preguntas",
    name: "UserStartPage",
    icon: Dashboard,
    component: PreguntasContainer,
    layout: "/auth",
  },
  {
    path: "/contestar",
    name: "UserStartPage",
    icon: Dashboard,
    component: PreguntasContestarContainer,
    layout: "/auth",
  },
  {
    path: "/modelo",
    name: "ModelPage",
    icon: Dashboard,
    component: ModelPage,
    layout: "/user",
  },
  {
    path: "/reset/:token",
    name: "ResetPage",
    icon: ResetPage,
    component: ResetPage,
    layout: "/user",
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    icon: Dashboard,
    component: Newsletter,
    layout: "/user",
  },
  {
    path: "/contact",
    name: "Contacto",
    icon: Dashboard,
    component: Contacto,
    layout: "/user",
  },
  {
    path: "/forgot",
    name: "Recover",
    icon: Dashboard,
    component: Recover,
    layout: "/user",
  },
  {
    path: "/login",
    name: "Login",
    icon: Dashboard,
    component: Login,
    layout: "/user",
  },
  {
    path: "/register/director",
    name: "RegisterDirector",
    icon: Dashboard,
    component: RegisterDirectorPage,
    layout: "/user",
  },
  {
    path: "/register/otros",
    name: "RegistroOtros",
    icon: Dashboard,
    component: RegistroOtros,
    layout: "/user",
  },
  {
    path: "/register/completar/:token",
    name: "RegistroCoordinadorCompletar",
    icon: Dashboard,
    component: RegistroCoordinadorContainer,
    layout: "/user",
  },

  {
    path: "/register/investigador",
    name: "RegisterInvestigador",
    icon: Dashboard,
    component: RegisterInvestigador,
    layout: "/user",
  },
  {
    path: "/start",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/user",
  },
  {
    path: "/register/ok",
    name: "RegisterOk",
    icon: Dashboard,
    component: RegisterOk,
    layout: "/user",
  },
  {
    path: "/register/okinvestigador",
    name: "RegisterOkInvestigador",
    icon: Dashboard,
    component: RegisterOkInvestigador,
    layout: "/user",
  },
  {
    path: "/register/okotros",
    name: "RegisterOkOtros",
    icon: Dashboard,
    component: RegisterOkOtros,
    layout: "/user",
  },
  {
    path: "/register",
    name: "Register",
    icon: Dashboard,
    component: RegisterPage,
    layout: "/user",
  },
  {
    path: "/privacity",
    name: "Privacidad",
    icon: Dashboard,
    component: PrivacidadPage,
    layout: "/user",
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin",
  },
];

export default dashboardRoutes;
