import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Card, CardContent, IconButton, Typography, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import refererImg from 'assets/img/referer.png';
import stainImage from "assets/img/stain_blue.webp";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    backgroundImage: `url(${stainImage})`,
    backgroundPosition: 'right 140%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',

    '& .title-site-width': {
      maxWidth: theme.breakpoints.values.md + 100,
      margin: 'auto',
      marginBottom: -32,
      paddingLeft: 12,
    }
  },
  carouselBox: {
    position: 'relative',
    paddingTop: 55,

    '& .scroll-menu-arrow': {
      position: 'absolute',
      top: 0,
      right: 30,
    },
    '& .menu-wrapper--inner': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .scroll-menu-arrow:first-of-type': {
      transform: 'translateX(-120%)',
    },
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    gap: 15,

    '& span': {
      color: '#AAAAAA',
    }
  },
  cardBox: {
    width: '80vw',
    whiteSpace: 'normal',
    marginRight: 30,

    [theme.breakpoints.up('sm')]: {
      width: 430,
    },
  },
  cardContent: {
    padding: 20,
  }
}));

const content = [
  {
    comment: 'Antes de tener un marco de referencia, la orientación en los centros educativos aparecía fragmentada y apenas había colaboración con el mundo profesional. Esto cambió con la implantación de los Gatsby Benchmarks en todos los centros de Inglaterra. Parte del éxito es que el marco es muy sólido y estable, y conecta a todos los agentes implicados en la orientación.',
    author: 'Ryan Gibson',
    position: 'Senior Adviser: Careers en Gatsby Foundation',
  },
  {
    comment: 'La incorporación del CEAP ha creado un puente sólido entre la educación y el mundo profesional: la coordinación es total y los beneficios son tangibles y elevan la calidad educativa en nuestro centro.',
    author: 'Isabel Marín',
    position: 'Directora del IES La Azucarera (Zaragoza)',
  },
  {
    comment: 'Se respeta la diversidad realmente cuando se considera normal y enriquecedora, no como algo deficitario que debe compensarse: la diversidad es la norma. La orientación debe valorar las potencialidades de cada persona en su contexto (no solo según un informe diagnóstico), adoptando una postura crítica y comprometida que cuestione las relaciones de poder y las desigualdades sociales.',
    author: 'Beatriz Malik ',
    position: 'Presidenta de la Asociación Española de Orientación y Psicopedagogía (AEOP)',
  },
  {
    comment: 'En Orientación es necesaria una mirada “hacia dentro” para el autoconocimiento con pruebas, entrevistas y tutorías. Simultáneamente una mirada “hacia fuera” para conocer la oferta formativa y tomar las decisiones más adecuadas para cada perfil personal y así hacer realidad el propio proyecto de vida.',
    author: 'Ana Cobos',
    position: 'Presidenta de la Confederación de Organizaciones de Psicopedagogía y Orientación de España (COPOE)',
  },
  {
    comment: 'Destacamos la importancia de las tutorías. El desarrollo de esta herramienta permite impulsar la participación e implicación de las familias, pero para ello los centros deben facilitar que el profesorado disponga del tiempo necesario y la administración, establecer las medidas necesarias de conciliación para poder asistir.',
    author: 'Begoña Ladrón de Guevara',
    position: 'Presidenta de la Confederación de padres de alumnos (COFAPA)',
  },
  {
    comment: 'Cuando acercamos los contenidos impartidos a su aplicación en un entorno laboral, además de aumentar la motivación de los alumnos, aseguramos la relevancia práctica de lo que aprenden. Trabajando las soft skills, les preparamos para enfrentarse a desafíos en un entorno profesional futuro.',
    author: 'María Olalla Cruz ',
    position: 'Coordinadora de Estrategia Académico-Profesional (CEAP) del IES Salvador Allende',
  },
  {
    comment: 'Los estudiantes que tienen experiencias en contextos laborales se sienten más tranquilos, seguros y motivados al tomar decisiones académicas. Las prácticas y estancias educativas les ayudan a descubrir intereses, descartar lo que no les gusta y proyectarse a futuro.',
    author: 'Eduard Cremades',
    position: 'Profesor de ciencias, orientador y tutor en la Escola Virolai',
  },
  {
    comment: 'Las experiencias en contextos laborales ofrecen al alumnado la oportunidad de acercarse a entornos reales, de mejorar sus competencias personales y sociales y de desarrollar habilidades prácticas; mientras que las empresas adquieren nuevas perspectivas innovadoras y se conectan al futuro. ',
    author: 'Juan Carlos Tejeda',
    position: 'Director de formación de la CEOE',
  },
  {
    comment: 'Desde el Ayuntamiento creamos www.terrassaocupacio.cat para mostrar en una única web toda la oferta de trabajo, formación, prácticas laborales en empresas y orientación del municipio. Esto es posible gracias a la colaboración de los agentes sociales, económicos y del ámbito de la formación profesional. A nivel municipal esta iniciativa nos proporciona un recurso excelente para facilitar las colaboraciones entre estos organismos, ofreciendo un servicio integral a la ciudadanía.',
    author: 'Carlos Pereira',
    position: 'Jefe del servicio de Empresa e Intermediación en el Ayuntamiento de Terrassa',
  },
  {
    comment: 'Para poder orientar bien a los adolescentes necesitamos facilitarles que puedan de alguna manera “tocar” lo que después van a estudiar con un programa integrado y continuo de actividades prácticas y de autoconocimiento, que les ofrezca experiencias que se desarrollen con sus profesores, el entorno empresarial y las familias.',
    author: 'Luis García',
    position: 'Presidente de FP Empresa',
  },
];

export function CarouselTestimonial() {
  const classes = useStyles();

  return (
    <div className={classes.carouselContainer}>
      <Typography className="title-site-width" variant="h5">
        Testimonios
      </Typography>

      <div className={classes.carouselBox}>
        <ScrollMenu
          alignCenter
          data={content.map(({comment, author, position}, i) => 
            <Card
              key={i}
              elevation={0}
              className={classes.cardBox}
            >
              <CardContent className={classes.cardContent}>
                <div className={classes.header}>
                  <img src={refererImg} />
                  <Typography variant="caption">
                    Clave {i + 1}
                  </Typography>
                </div>
                <Typography>{comment}</Typography>
                <Typography className="mt-3 mb-1" variant="h6">
                  {author}
                </Typography>
                <Typography variant="caption">
                  {position}
                </Typography>
              </CardContent>
            </Card>
          )}
          arrowLeft={
            <IconButton color="primary" size="small">
              <ChevronLeftIcon />
            </IconButton>
          }
          arrowRight={
            <IconButton color="primary" size="small">
              <ChevronRightIcon />
            </IconButton>
          }
        />
      </div>
    </div>
  );
}
