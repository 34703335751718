import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Language } from "components/Language";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  flipCard: {
    backgroundColor: 'transparent',
    width: 242,
    height: 330,
    perspective: 1000,

    '& .flip-card-inner': {
      position: 'relative',
      width: '100%',
      height: '100%',
      transition: 'transform 0.6s',
      transformStyle: 'preserve-3d',
      boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px',
      borderRadius: 20,
    },
    '&:hover .flip-card-inner': {
      transform: 'rotateY(180deg)',
    },
    '& .flip-card-front, & .flip-card-back': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '-webkit-backface-visibility': 'hidden',
      backfaceVisibility: 'hidden',
    },
    '& .flip-card-front': {
      backgroundColor: '#fff',
      borderRadius: 20,
      overflow: 'hidden',
    },
    '& .featured-img': {
      height: 162,
      objectFit: 'cover',
    },
    '& .flip-card-back': {
      borderRadius: 20,
      backgroundColor: 'var(--corpo-color)',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'rotateY(180deg)',
      padding: 20,

      '& br': {
        lineHeight: 1, 
      }
    },
    '& .plus-flip-action': {
      position: 'absolute',
      bottom: 12,
      right: 12,
    }
  },
}));

export function FlipCard({title, description, image}) {
  const classes = useStyles();

  return (
    <div className={classes.flipCard}>
      <div class="activity-box flip-card-inner">
        <div class="flip-card-front">
          <img width="100%" className="featured-img" src={image} alt=""/>
          <Typography className="m-3" variant="h6">
            <Language  langKey={title} />
          </Typography>
          <AddIcon className="plus-flip-action" color="primary" />
        </div>
        <div class="flip-card-back">
          <Typography>
            <Language parse langKey={description} />
          </Typography>
        </div>
      </div>
    </div>
  );
}
