import React, { useState } from "react";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import { useStatus } from "utils/custon-hooks";
import { useDispatch, useSelector } from "react-redux";
import { downloadReport } from "actions/reports.actions";
import { snackbarSetting } from "actions/settings.action";
import { Language, getRawMessage } from "components/Language";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  presentation: {
    '& > .container': {
      maxWidth: theme.breakpoints.values.md,
      padding: 0,
    },
    '& form': {
      color: '#fff',
      padding: 35,
      borderRadius: 30,
      maxWidth: 800,
      margin: 'auto 15px',
      backgroundColor: 'var(--corpo-color)',

      '& > div > div > div > div::before': {
        borderBottomColor: '#fff',
      },
      '& > div > div > div >  label': {
        color: '#fff',
      },

      '& label[class^=MuiFormControlLabel-root]': {
        color: '#fff',
      },
      '& label[class^=MuiFormControlLabel-root] a': {
        color: '#fff',
        fontWeight: 900,
        textDecoration: 'underline'
      },
    },
  },
}));

const communities = [
  'Andalucía',
  'Aragón',
  'Canarias',
  'Cantabria',
  'Castilla y León',
  'Castilla - La Mancha',
  'Catalunya',
  'Comunidad de Madrid',
  'Comunidad Foral de Navarra',
  'Comunitat Valenciana',
  'Extremadura',
  'Galicia',
  'Illes Balears',
  'Región de Murcia,',
  'País Vasco',
  'Principado de Asturias',
  'La Rioja',
  'Ceuta',
  'Melilla',
]

const initialState = {
  gender: '',
  company: '',
  postalCode: '',
  name: '',
  lastName: '',
  email: '',
  ccaa: '',
}

const genders = [
  'report_page.form.male',
  'report_page.form.female',
  'report_page.form.other_gender',
];

export function PageIFrameSection() {
  const classes = useStyles();
  const [state, setState] = useStatus({...initialState});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.setting.locale);

  const handleChange = (event) => {
    setState({
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      await new Promise((resolve, reject) => (
        dispatch(downloadReport({
          body: state,
          resolve,
          reject,
        }))
      ));

      const anchor = document.createElement('a');
      anchor.href = `/ES/Informe_anual_Xcelence_2023.pdf`;
      anchor.download = 'Informe_anual_Xcelence_2023';

      document.body.appendChild(anchor);
      anchor.click();

      anchor.remove();

      dispatch(snackbarSetting({
        severity: 'success',
        message: getRawMessage('report_page.form.success')
      }))

      setState({...initialState});

    } catch (error) {
      dispatch(snackbarSetting({
        severity: 'error',
        message: getRawMessage('report_page.form.error')
      }))

      console.log(error);
    }

    setLoading(false)
  }

  return (
    <div className={classes.presentation} id="send_form">
      <div className="container">
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">
            <Language langKey="report_page.download_study" />
          </Typography>
          <Typography className="mb-4">
            <Language langKey="report_page.study_knowing" />
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.gender')}*</InputLabel>
              <FormControl required fullWidth>
                <Select
                  value={state.gender}
                  onChange={handleChange}
                  name='gender'
                  variant="outlined"
                  displayEmpty
                  required
                >
                  <MenuItem disabled value="">
                    <span className="disabled">Selecciona una opción</span>
                  </MenuItem>
                  {genders.map((item) =>
                    <MenuItem key={item} value={getRawMessage(item)}>
                      <Language langKey={item}>{item}</Language>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.email')}*</InputLabel>
              <TextField
                fullWidth
                value={state.email}
                name="email"
                type="email"
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                onChange={handleChange}
                placeholder="Tu e-mail"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.name')}*</InputLabel>
              <TextField
                fullWidth
                value={state.name}
                name='name'
                variant="outlined"
                onChange={handleChange}
                inputProps={{ maxLength: 150 }}
                placeholder="Tu nombre"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.last_name')}*</InputLabel>
              <TextField
                fullWidth
                value={state.lastName}
                name="lastName"
                variant="outlined"
                inputProps={{ maxLength: 150 }}
                placeholder="Tu apellido"
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.postal_code')}*</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                value={state.postalCode}
                name='postalCode'
                inputProps={{ maxLength: 150 }}
                onChange={handleChange}
                placeholder="Código postal de la organización"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.ccaa')}</InputLabel>
              <FormControl required fullWidth>
                <Select
                  value={state.ccaa}
                  onChange={handleChange}
                  name='ccaa'
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <span className="disabled">Selecciona una opción</span>
                  </MenuItem>
                  {communities.map((item) =>
                    <MenuItem value={item} key={item}>{item}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{getRawMessage('report_page.form.company_name')}*</InputLabel>
              <TextField
                fullWidth
                value={state.company}
                variant="outlined"
                name="company"
                inputProps={{ maxLength: 150 }}
                placeholder="Organización a la que pertenece"
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    name="information"
                    color="secondary"
                  />
                }
                label={<Language parse tag="span" langKey="report_page.form.terms" />}
              />
            </Grid>
          </Grid>

          <Grid container className="mt-4">
            <Grid item>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                disabled={loading}
                endIcon={loading
                  ? <CircularProgress color="inherit" size={14} />
                  : <ExpandMoreIcon />
                }
              >
                <Language langKey="report_page.form.download" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
