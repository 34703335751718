import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Language } from "components/Language";
import stainImage from "assets/img/stain.webp";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  presentation: {
    backgroundImage: `url(${stainImage})`,
    backgroundPosition: 'right 80%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    
    '& > .container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      rowGap: 35,
      maxWidth: theme.breakpoints.values.md - 200,
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        padding: '130px 12px',
      },

      '&  h3': {
        color: 'var(--corpo-color)',
        fontWeight: 'bold',
      },
      '& > a': {
        width: 'fit-content'
      },

      '&  h5': {
        display: 'inline',
      },
    },
  },
}));

export function PagePresentation() {
  const classes = useStyles();

  return (
    <div className={classes.presentation}>
      <div className="container">
        <Typography variant="h3">
          <Language langKey="report_page.title" />
        </Typography>
        <Typography>
          <Language langKey="report_page.description" />
        </Typography>
        <Button
          variant="contained"
          endIcon={<ExpandMoreIcon />}
          href="#send_form"
          color="primary">
          <Language langKey="report_page.download_study" />
        </Button>
      </div>
    </div>
  );
}
