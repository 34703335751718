import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import NavBarAuth from "../../NavBar/NavBarAuth.js";
import { Box, Grid, ThemeProvider, Typography, createTheme } from "@material-ui/core";

import { AwardsWelcome } from "./AwardsWelcome.js";
import { AwardsCategory } from "./AwardsCategory.js";

import { AwardsJuries } from "./AwardsJuries.js";

import connection from 'assets/img/awards/connection.png';
import information from 'assets/img/awards/information.png';
import job from 'assets/img/awards/job.png';
import road from 'assets/img/awards/road.png';
import ReactPlayer from "react-player";
import Footer from "components/Footer/Footer.js";
import { Language } from "components/Language.js";
import { CategoryCard } from "./components/CategoryCard.js";

const outerTheme = createTheme({
  shape: {
    borderRadius: 15,
  },
  palette: {
    primary: {
      main: '#dc0740',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'MuseoSans',
      'sans-serif',
    ],
  },
});

export function Awards() {

  return (
    <div>
      {localStorage.getItem("xcauth") === "true"
        ? <NavBarAuth />
        : <NavBar />
      }

      <ThemeProvider theme={outerTheme}>
        <AwardsWelcome />

        <Grid style={{ marginBottom: 25 }} container justifyContent="center">
          <Box style={{ overflow: 'hidden', maxWidth: 900, borderRadius: 10, width: '100%' }}>
            <ReactPlayer
              children
              height={506}
              width="100%"
              url="https://www.youtube-nocookie.com/embed/X70DOKL_Dk8" />
          </Box>
        </Grid>

        <AwardsCategory
          id="winners"
          title={"Proyectos ganadores"}
          fullWidth
          color="#e6e6e6"
        />

        <CategoryCard />

        <AwardsCategory
          title={"1ª edición de los Premios a la excelencia en orientación"}
          fullWidth
          color="#dc0740"
          textColor="#fff"
          detail={<div>
            <Typography className="mb-5" variant="body1">La primera edición de los premios ha tenido una gran acogida y ha contado con la participación de muchos centros educativos de Primaria, Secundaria o Bachillerato de diferentes comunidades autónomas. Los premios establecen cuatro categorías que representan las áreas fundamentales de la orientación académica y profesional.</Typography>
            <a href="/Bases_de_los_Premios_a_la_excelencia_en_orientacion_2023_24.pdf" target="_blank" className="sw-btn-primary my-3">
              CONSULTA LAS BASES DE ESTA EDICIÓN
            </a>
          </div>}
        />

        <AwardsCategory
          title={'Categorías de los premios'}
          fullWidth
          borderBottom
          color="#fff"
          detail={'\n'}
        />

        <AwardsCategory
          title={<Language langKey="award_page.job_title" />}
          detail={'\n'}
          // detail={<Language parse langKey="award_page.job_detail" />}
          icon={job}
          color="#de6c6c"
        />

        <AwardsCategory
          title={<Language langKey="award_page.road_title" />}
          detail={'\n'}
          icon={road}
          color="#63b26b"
        />

        <AwardsCategory
          title={<Language langKey="award_page.information_title" />}
          detail={'\n'}
          icon={information}
          color="#cd7e47"
        />

        <AwardsCategory
          title={<Language langKey="award_page.connection_title" />}
          detail={'\n'}
          icon={connection}
          color="#996ba3"
        />

        {/* <AwardSteps /> */}

        <AwardsJuries />

        <Footer />
      </ThemeProvider>
    </div>
  );
}
