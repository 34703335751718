import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import {Language} from 'components/Language';

const Carousel_Elegir = () => {
  const content = [
    {
      pos: 1,
      texto: <Language langKey="ceap.choose_one"/>,
    },
    {
      pos: 2,
      texto: <Language langKey="ceap.choose_two"/>,
    },
    {
      pos: 3,
      texto: <Language langKey="ceap.choose_three"/>,
    },
    {
      pos: 4,
      texto: <Language langKey="ceap.choose_four"/>,
    },
  ];

  return (
    <Carousel className="caruselCeap" interval={8000}>
      {content.map((item, index) => (
        <Carousel.Item key={index}>
          <div className="caruselInsideCeap paddingCeap">
          <div className="iconCeap">
              <img
                className="imgCaruselCeap"
                src={`/images/ceap/Num${item.pos}.png`}
              />
            </div>
            <div
              className="txtHome padBottomHomeMore  paddingCeap textLeft itemCaruselCeap"
            >{item.texto}</div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
} 

export default Carousel_Elegir;
