import React, { useState, useEffect, useRef } from "react";
import { StarBorder, Star, InfoOutlined, ArrowDropDown, ArrowDropDownRounded, ClearOutlined, Replay } from "@material-ui/icons";
import { getRawMessage, Language } from "components/Language.js";
import { Grid, makeStyles, Tooltip, Checkbox, FormControlLabel, FormGroup, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@material-ui/core";
import { useStatus } from "utils/custon-hooks";
import { useSelector } from "react-redux";
import Search from "antd/lib/input/Search";

const useStyles = makeStyles((theme) => ({
  filterResources: {
    '& label': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
    textAlign: "justify"
  },
  tooltipArrow: {
    color: theme.palette.common.black,
  },
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    }
  },
  accordionSummary: {
    padding: 0,
    marginLeft: -15,
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIcon': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  checkboxForm: {
    alignItems: 'start',
    '& .MuiCheckbox-root': {
      paddingTop: 3,
    }
  }
}));

const initialState = {
  courses: [],
  prospects: [],
  keys: [],
  kinds: [],
  resourceTypes: [],
  highlights: false,
  search: '',
}

const searchParamsLabel = {
  cur: 'courses',
  per: 'prospects',
  cla: 'keys',
  tip: 'kinds',
  rt: 'resourceTypes',
  res: 'search',
  des: 'highlights',
}

function exchangeMap(tmap, tkey) {
  for(const key in tmap) {
    if(tmap[key] === tkey) {
      return key; 
    }
  }
}

function RecourcesFilter(props) {
  const [state, setState] = useStatus({ ...initialState });
  const keys = useSelector((state) => state.claveReducer.claveList);
  const courses = useSelector((state) => state.centroReducer.getCursos);
  const classes = useStyles();
  const perspectives = useSelector(
    (state) => state.recursosReducer.perspectivasList
  );
  const recursos = useSelector((state) => state.recursosReducer.recursosList);
  const uri = useRef(null);

  const materiaTypes = useSelector(
    (state) => state.recursosReducer.tipoMaterialList
  );

  function onChange(e) {
    setState({ highlights: e.target.checked });

    setUriSearch('des', Number(e.target.checked));
  }

  const setUriSearch = (key, value) => {
    if(Array.isArray(value)) {
      value = value.join('-')
    }
    const url = new URL(window.location.href);

    if(window.history.pushState) {
      if(!value) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value);
      }

      window.history.pushState({}, '', url);
    }
  }

  const onChangeFormGroup = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let next; 
    console.log(name);

    if(event.target.checked) {
      next = [...state[name], value]
    } else {
      next = state[name].filter(item => item !== value);
    }

    setState({ [name]: next });
    setUriSearch(exchangeMap(searchParamsLabel, name), next);
  }

  const changeSearch = (e) => {
    setState({ search: e.target.value });

    setUriSearch('bus', e.target.value);
  };

  const cleanFiler = () => {
    setState({ ...initialState });

    const url = new URL(window.location.href);

    for (const key in searchParamsLabel) {
      url.searchParams.delete(key);
    }

    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    if (recursos && uri.current === null) {
      const url = new URL(window.location.href);
      const nextState = {};

      url.searchParams.forEach((value, key) => {
        if (['cur', 'per', 'cla', 'tip', 'rt'].indexOf(key) !== -1 && value) {
          value = value.split('-').map(item => item);
        } else if (key === 'des') {
          value = Boolean(value);
        }

        nextState[searchParamsLabel[key]] = value;
      });

      if (Object.keys(nextState).length > 0) {
        setState(nextState);
      } else {
        props.onFilter(state);
      }

      uri.current = url;
    }
  }, [recursos]);

  useEffect(() => {
    props.onFilter(state);
  }, [state]);


  return (
    <div className={classes.filterResources}>
      <Search
        className="customInputRecursos"
        allowClear
        placeholder={getRawMessage("config.search")}
        enterButton
        onChange={changeSearch}
        value={state.search}
        style={{ width: "100%", marginBottom: 15 }}
      />
      <FormGroup onChange={onChangeFormGroup}>
        <FormControlLabel
          name="resourceTypes"
          checked={state.resourceTypes?.includes('RESOURCE')}
          control={<Checkbox size="small" color="primary" />}
          label="Recursos"
          value="RESOURCE"
        />
        <FormControlLabel
          name="resourceTypes"
          checked={state.resourceTypes?.includes('BEST_PRACTICE')}
          control={<Checkbox size="small" color="primary" />}
          label="Buenas Prácticas"
          value="BEST_PRACTICE"
        />
      </FormGroup>
      <FormControlLabel
        name="popular"
        onChange={onChange}
        checked={state.highlights}
        control={
          <Checkbox
            color="primary"
            name="keys"
            icon={<StarBorder />}
            checkedIcon={<Star />}
          />
        }
        label={
          <Grid container alignItems="center">
            <Language langKey="assets.featured" />&nbsp;&nbsp; 
            <Tooltip
              classes={{
                arrow: classes.tooltipArrow,
                tooltip: classes.tooltip,
              }}
              title={<Language langKey="assets.highlights" />}
              arrow
            >
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Grid>
        }
      />

      <div className="txtResultadosTitle toUpperCase fontStrong customMt2">
        <Language langKey="filters" />
      </div>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ArrowDropDownRounded fontSize="large" />}
          className={classes.accordionSummary}
        >
          <Typography>
            <Language langKey="keys_single" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup onChange={onChangeFormGroup}>
            {keys?.map((item) => (
              <FormControlLabel
                className={classes.checkboxForm}
                key={item.num}
                name="keys"
                value={item.num}
                checked={state.keys?.includes(String(item.num))}
                control={<Checkbox size="small" color="primary" />}
                label={`${item.num}. ${item.titulo}`}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ArrowDropDownRounded fontSize="large" />}
          className={classes.accordionSummary}
        >
          <Typography>
            <Language langKey="assets.form.study_grade" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup onChange={onChangeFormGroup}>
            {courses?.map((item) => (
              <FormControlLabel
                className={classes.checkboxForm}
                name="courses"
                key={item.id}
                checked={state.courses?.includes(String(item.id))}
                value={item.id}
                control={<Checkbox size="small" color="primary" />}
                label={item.curso}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      {!(state.resourceTypes?.includes('BEST_PRACTICE') && state.resourceTypes?.length === 1) && (
        <>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ArrowDropDownRounded fontSize="large" />}
              className={classes.accordionSummary}
            >
              <Typography>
                <Language langKey="assets.material_type" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup value onChange={onChangeFormGroup}>
                {materiaTypes?.map((item) => (
                  <FormControlLabel
                    className={classes.checkboxForm}
                    name="kinds"
                    key={item.id}
                    value={item.id}
                    checked={state.kinds?.includes(String(item.id))}
                    control={<Checkbox size="small" color="primary" />}
                    label={item.name}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ArrowDropDownRounded fontSize="large" />}
              className={classes.accordionSummary}
            >
              <Typography>
                <Language langKey="perspective" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup onChange={onChangeFormGroup}>
                {perspectives?.map((item) => (
                  <FormControlLabel
                    className={classes.checkboxForm}
                    name="prospects"
                    checked={state.prospects?.includes(String(item.id))}
                    key={item.id}
                    value={item.id}
                    control={<Checkbox size="small" color="primary" />}
                    label={item.name}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <Button
        className="mt-3"
        variant="text"
        color="primary"
        startIcon={<Replay />}
        onClick={cleanFiler}
      >
        <Language langKey="clean_filter" />
      </Button>
    </div>
  );
}

export default RecourcesFilter;
