import React from 'react';
import { NavLink } from "react-router-dom";
import { Language } from "components/Language.js";
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  newsLetter: {
    color: '#fff',
    textAlign: 'center',
    backgroundColor: '#dc0740',
    padding: '25px 0 45px',
    '& > h5': {
      color: '#fff',
      margin: 0,
      fontWeight: 'bold',
    },
    '& > p': {
      maxWidth: 700,
      margin: '20px auto 45px',
    },
  },
}));

export const NewsLetter = () => {
  const classes = useStyle();

  return (
    <div className={classes.newsLetter}>
      <h5>
        <Language langKey='newsletter' />
      </h5>
      <p>
        <Language langKey="dashboard.news" />
      </p>
      <NavLink
        className="sw-btn-secondary"
        to="/user/newsletter"
      >
        <Language langKey="dashboard.suscription" />
      </NavLink>
    </div>
  );
}