import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Card, CardContent, IconButton, Typography, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Language } from "components/Language";

const useStyles = makeStyles((theme) => ({
  carouselBox: {
    position: 'relative',
    paddingTop: 55,

    '& .scroll-menu-arrow': {
      position: 'absolute',
      top: 0,
      right: 25,
    },
    '& .menu-wrapper--inner': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .scroll-menu-arrow:first-of-type': {
      transform: 'translateX(-120%)',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    gap: 15,

    '& span': {
      color: '#AAAAAA',
    }
  },
  cardBox: {
    width: '80vw',
    whiteSpace: 'normal',
    marginRight: 30,
  },
  cardContent: {
    padding: '25px 20px',
  }
}));

export function CarouselFinding({content}) {
  const classes = useStyles();

  return (
    <div className={classes.carouselBox}>
      <ScrollMenu
        alignCenter
        data={content.map(({description, title}, i) => 
          <Card
            key={i}
            elevation={0}
            className={classes.cardBox}
          >
            <CardContent className={classes.cardContent}>
              <Typography className="mb-2" variant="h6">
                <Language langKey={title} />
              </Typography>
              <Typography><Language langKey={description} /></Typography>
            </CardContent>
          </Card>
        )}
        arrowLeft={
          <IconButton color="primary" size="small">
            <ChevronLeftIcon />
          </IconButton>
        }
        arrowRight={
          <IconButton color="primary" size="small">
            <ChevronRightIcon />
          </IconButton>
        }
      />
    </div>
  );
}
