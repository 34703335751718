import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";

import { Language } from "components/Language";
import { useSelector } from "react-redux";

const useStyle = makeStyles(theme => ({
  welcomeSeccion: {
    position: 'relative',
    textAlign: 'center',
    '& img.presentation-img': {
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    '& h3': {
      position: 'absolute',
      fontSize: '32px',
      background: 'var(--corpo-color)',
      right: '-148px',
      top: '69px',
      color: 'white',
      padding: '7px 200px',
      transform: 'rotate(25deg)',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      margin: '0',
      zIndex: '3',
    },
    '& a': {
      display: 'inline-block',
      fontSize: 15,
      paddingRight: 60,
      paddingLeft: 60,
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: '"MUSEOSANS", "Roboto", "Helvetica"',
      marginBottom: '3rem',
    },
    '& a:visited': {
      color: '#fff',
    }
  },
  awardJury: {
    padding: '20px 10px 60px',
    '& > div': {
      maxWidth: 900,
      margin: '0 auto',
      '& > h4': {
        color: theme.palette.primary.main,
        borderBottom: '1px solid #9e9e9e',
        margin: 0,
        padding: '0 0 20px',
        fontWeight: 'bold',
      },
      '& > div': {
        display: 'flex',
        gap: 25,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '& > div': {
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 20,
        },
        '& p': {
          width: 100,
          textAlign: 'right',
          margin: 0,
          fontSize: 14
        },
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      },
      [theme.breakpoints.down('xs')]: {
        '&::before': {
          width: 50,
        },
      },
    },
  },
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    position: 'relative',
    overflow: 'hidden',
  },
  awardDetails: {
    textAlign: 'center',
    padding: '0 0 35px',
    '& > h6': {
      color: theme.palette.primary.main,
      fontFamily: '"MUSEOSANS", "Roboto", "Helvetica"',
      fontSize: '1.4rem',
      fontWeight: 400,
      textTransform: 'none',
      maxWidth: 900,
      width: '80%',
      margin: 'auto',
      marginBottom: 20,
      lineHeight: 1.3,
    },
  }
}));


export function AwardsWelcome() {
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
  const locale = useSelector((state) => state.setting.locale);
  const classes = useStyle();

  return (
    <div className={classes.welcomeSeccion}>
      <div className={classes.welcomeContainer}>
        <img className="presentation-img" src={matches
          ? require(`assets/img/${locale}/awards/banner_awards_desktop.webp`)
          : require(`assets/img/${locale}/awards/banner_awards_desktop.webp`)} />  
      </div>

      <div className={classes.awardDetails}>
        <h6><Language parse langKey="award_page.description" /></h6>
      </div>

      <a href="#winners" className="sw-btn-primary">
        <Language langKey="award_page.meet_winners" />
      </a>
    </div>
  );
}
