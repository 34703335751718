import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../NavBar/NavBar.js";
import NavBarAuth from "../../NavBar/NavBarAuth.js";
import { Link } from "react-router-dom";
import recursosImg from "assets/img/recursos.png";
import { useSelector, useDispatch } from "react-redux";
import { claveActions } from "actions/claveActions";
import { centroActions } from "actions/centroActions";
import * as _ from "lodash";
import { recursosActions } from "actions/recursosActions";
import {
  Input,
  Space,
  List,
  Card,
  Avatar,
  Typography as AntTypography
} from "antd";

import {
  DownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Button from "@material-ui/core/Button";

import "./ListRecursos.css";
import { getRawMessage, Language } from "components/Language.js";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import RecourcesFilter from "./components/ResourcesFilter.js";

const { Paragraph } = AntTypography;

const useStyles = makeStyles((theme) => ({
  tinyBanner: {
    backgroundColor: '#dc0740',
    borderRadius: 15,
    color: '#fff',
    textTransform: 'unset',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

let listLocale = {
  emptyText: <p style={{maxWidth: 400, margin: '2rem auto'}}><Language langKey="assets.empty_result" /></p>,
};

function ListRecursos() {
  const dispatch = useDispatch();
  const [recursosList, setRecursosList] = React.useState([]);
  const [element, setElement] = React.useState(Math.random());
  const [pageList, setPageList] = useState(9);
  const [first, setIsFirst] = React.useState(true);
  const classes = useStyles();

  const [showFilters, sethowFilters] = useState(false);

  const locale = useSelector((state) => state.setting.locale);
  const recursos = useSelector((state) => state.recursosReducer.recursosList);
  const forceUpdate = React.useState()[1].bind(null, {}); //

  useEffect(() => {
    dispatch(recursosActions.getPerspectivas());
    dispatch(recursosActions.getTipoMaterial());
    dispatch(recursosActions.getRecursos());
    dispatch(claveActions.getClaves());
    dispatch(centroActions.getCursos());
  }, [locale]);

  useEffect(() => {
    dispatch(recursosActions.getComunidadesRecursos());
  }, [])

  const doFilter = (state) => {
    if (recursos) {
      let mFiltered = recursos;

      if(state.highlights) {
        mFiltered = _.filter(mFiltered, { destacado: Number(state.highlights) });
      }

      if (state.resourceTypes.length > 0) {
        mFiltered = mFiltered.filter(item => state.resourceTypes.includes(item.resourceType));
      }

      if (state.search !== "") {
        mFiltered = mFiltered.filter((item) => {
          return (
            item.titulo?.toLowerCase().includes(state.search.toLowerCase()) ||
            item.description?.toLowerCase().includes(state.search.toLowerCase())
          );
        });
      }

      if (state.keys.length > 0) {
        mFiltered = _.filter(mFiltered, (v) => {
          let mClaves = v.claves?.split(",");

          return _.intersection(state.keys, mClaves).length > 0;
        }
        );
      }

      if (state.kinds.length > 0) {
        mFiltered = _.filter(mFiltered, (v) => _.includes(state.kinds, String(v.tipo)));
      }

      if (state.courses.length > 0) {
        mFiltered = _.filter(mFiltered, (v) => {
          let mCursos = v.cursos.split(",");

          return _.intersection(state.courses, mCursos).length > 0;
        });
      }

      if (state.prospects.length > 0) {
        mFiltered = _.filter(mFiltered, (v) =>

          _.includes(state.prospects, String(v.perspectiva))
        );
      }

      setRecursosList(mFiltered);

      if (first) {
        setPageList(9);
      } else {
        setPageList(12);
      }

      setElement(Math.random());
    }
  };


  const moreRecursos = () => {
    if (pageList === 9) {
      setPageList(12);
    } else {
      setPageList(pageList + 12);
    }

    setIsFirst(false);

    setElement(Math.random());
    forceUpdate();
  };

  return (
    <div>
      {localStorage.getItem("xcauth") === "true"
        ? <NavBarAuth />
        : <NavBar />
      }

      <div>
        <div className="bgWhite ">
          <Grid
            container
            spacing={3}
            className="bgGrey cornerHomeRight homeContainer homeContainerRecursos"
          >
            <Grid
              className={`itemHomeResp ${showFilters ? 'blur' : ''}`}
              item
              xs={12}
              sm={7}
            >
              <div className="marginLeftHome">
                <div className="h1home padBottomHome">
                  <Language langKey="assets.title" />
                </div>

                <div className="txtHome padBottomHomeMore" >
                  <p><Language langKey="assets.param_one" parse /></p>
                  <p><Language langKey="assets.param_two" parse /></p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} className="imgCenter">
              <img
                className={showFilters ? 'blur' : undefined}
                width={180}
                src={recursosImg}
              />
              <Typography className={classes.tinyBanner} variant="h6">
                <Language langKey="assets.bbpp_form_ad" parse />
              </Typography>
              <Typography>
                <Language langKey="assets.bbpp_form_link" />
                <Link to={{pathname: `/${locale.toLowerCase()}/buenaspracticas`}}>
                  <Language langKey="assets.bbpp_form_link_target" />
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className="bgWhite marginRecurso">
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <>
                <div
                  className={`filtrosTablet ${showFilters ? 'showElement' : 'hideElement'
                    }`}
                >
                  <div
                    className={`forMobile iconsclose-verde `}
                    onClick={() => sethowFilters(false)}
                  >
                    <CloseOutlined width={25} />
                  </div>
                  <RecourcesFilter
                    onFilter={doFilter}
                  />
                </div>
              </>
            </Grid>

            <Grid
              className={showFilters ? 'blur' : ''}
              item
              xs={12}
              lg={9}
            >
              <div>
                <div className="totalRecursos">
                  {recursosList ? recursosList.length : 0}{" "}
                  <Language langKey="resources" />
                </div>

                <div
                  className={` forMobile mobileW ${showFilters ? "blur" : ""} `}
                >
                  <div className="rowFil">
                    <div className="columnFil">
                      <Input
                        value=""
                        onClick={() => sethowFilters(true)}
                        placeholder={getRawMessage("filters")}
                        suffix={<DownOutlined />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="marginRecursosB"></div>
              <List
                key={element}
                className="marginSubTitleRecursos"
                grid={{
                  gutter: 24,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 3,
                  xxl: 3,
                }}
                pagination={
                  ({ pageSize: pageList },
                    { hideOnSinglePage: true },
                    { defaultPageSize: pageList })
                }
                locale={listLocale}
                dataSource={recursosList}
                renderItem={(item) => {
                  const isBestPractice = item.resourceType === 'BEST_PRACTICE';

                  const actions = [
                    <div style={{ height: 50 }}>
                      <div className="subtipoRecurso">
                        <Language langKey="perspective" />
                      </div>
                      <div className="actRecurso">
                        {item.pers ? item.pers : "-"}
                      </div>
                    </div>,
                    <div style={{ height: 50 }}>
                      <div className="subtipoRecurso">
                        <Language langKey="key" />:
                      </div>
                      <div>
                        {item.claves?.split(",").map((clave, i) => {
                          return (
                            <Space key={i} size={8} wrap>
                              <Avatar
                                style={{
                                  marginLeft: 3,
                                  color: "#ffffff",
                                  backgroundColor: item.colores?.split(
                                    ","
                                  )[i],
                                }}
                              >
                                {clave}
                              </Avatar>
                            </Space>
                          );
                        })}
                      </div>
                    </div>
                  ]

                  if(!isBestPractice) {
                    actions.push(
                      <div style={{ height: 50, textAlign: "center" }}>
                        <img
                          style={{
                            width: 30,
                            height: 30,
                            marginTop: 10,
                          }}
                          src={
                            item.precio !== 0
                              ? "/images/recursos/pago.png"
                              : "/images/recursos/free.png"
                          }
                        />
                      </div>
                    );
                  }

                  return (
                    <List.Item>
                      <Link
                        className="linkaRecurso"
                        to={{ pathname: "/user/viewrecurso/" + item.id }}
                      >
                        <Card
                          className={`cardRecurso ${isBestPractice ? ' best_practice' : ''}`}
                          actions={actions}
                        >
                          {item.destacado === 1 && (
                            <div className="imgDestacado">
                              <img src={"/images/recursos/destacado.png"} />
                            </div>
                          )}
                          <div className="contentRecurso">
                            <div className="tipoRecurso cover">
                              <img className="imgTipoRecurso"
                                src={isBestPractice
                                  ? '/images/recursos/best_practice.png'
                                  : '/images/recursos/t_' + item.tipo + '.png'
                                }
                                onError={(event) =>
                                  event.target.src = '/images/recursos/t_6.png'
                                }
                              />
                            </div>
                            <div className="tipoRecurso">{item.tipom}</div>
                            <Paragraph
                              ellipsis={{
                                rows: 2,
                                expandable: false,
                                symbol: "more",
                              }}
                            >
                              <div className="tituloRecurso">{item.titulo}</div>
                            </Paragraph>
                            <Paragraph
                              ellipsis={{
                                rows: 4,
                                expandable: false,
                                symbol: "more",
                              }}
                              className="descRecurso"
                            >
                              {item.cuerpo && !isBestPractice
                                ? item.cuerpo
                                : item.description
                              }
                            </Paragraph>
                            <div
                              className="readMore"
                              style={{ cursor: "pointer" }}
                            >
                              <Language langKey="read_more" />
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </List.Item>
                  );
                }}
              />

              {recursosList.length > pageList && (
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => moreRecursos()}
                    className={`btnAccede`}
                    style={{ cursor: "pointer" }}
                  >
                    <Language langKey="view_more" />
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ListRecursos;
