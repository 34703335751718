import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Language } from "components/Language";
import stainImage from "assets/img/stain_single.webp";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import standardWheel from "assets/img/ES/wheel.png";

const useStyles = makeStyles(theme => ({
  presentation: {
    backgroundImage: `url(${stainImage})`,
    backgroundPosition: 'center',
    backgroundSize: '35%',
    backgroundRepeat: 'no-repeat',
    
    '& .container': {
      display: 'flex',
      gap: 40,
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
      '& img': {
        width: '100%',
        maxWidth: 430,
        margin: 'auto',
        height: 'auto',
      },
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
        flexDirection: 'row',
        '& img': {
          width: 430,
        }
      }
    },
    '& .block-description': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      rowGap: 35,
      position: 'relative',
      minWidth: 300,

      '&  h4': {
        color: 'var(--corpo-color)',
        fontWeight: 'bold',
      },
      '& > a': {
        width: 'fit-content'
      },

      '&  h5': {
        display: 'inline',
      },
    },
  },
}));

export function WheelReport() {
  const classes = useStyles();

  return (
    <div className={classes.presentation}>
      <div className="container">
        <div className="block-description">
          <Typography variant="h4">
            Todos los centros que usan Xcelence han mejorado su desempeño en las 10 claves.
          </Typography>
          <Typography>
            Tener un marco de calidad es el primer paso para priorizar la orientación dentro del proyecto educativo en los centros.
          </Typography>
          <Button
            variant="contained"
            endIcon={<ExpandMoreIcon />}
            href="#send_form"
            color="primary">
            <Language langKey="report_page.download_study" />
          </Button>
        </div>
        <img height={430} width={430} src={standardWheel} />
      </div>
    </div>
  );
}
