import { Language } from "components/Language";
import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";


const Carousel_Funciones = () => {
    const content = [
      {
        pos: 1,
        title: <Language langKey="ceap.roles_one.title" />,
        items: [
          <Language langKey="ceap.roles_one.item_one" />,
          <Language langKey="ceap.roles_one.item_two" />,
        ]
      },
      {
        pos: 2,
        title: <Language langKey="ceap.roles_two.title" />,
        items: [
          <Language langKey="ceap.roles_two.item_one" />,
          <Language langKey="ceap.roles_two.item_two" />,
        ]
      },
      {
        pos: 3,
        title: <Language langKey="ceap.roles_three.title" />,
        items: [
          <Language langKey="ceap.roles_three.item_one" />,
          <Language langKey="ceap.roles_three.item_two" />,
        ]
      },
      {
        pos: 4,
        title: <Language langKey="ceap.roles_four.title" />,
        items: [
          <Language langKey="ceap.roles_four.item_one" />,
          <Language langKey="ceap.roles_four.item_two" />,
        ]
      },
    ];

  return (
    <Carousel  className="caruselCeap" interval={8000}>
      {content.map(({pos, title, items}, index) => (
        <Carousel.Item key={index}>
          <div  className="caruselInsideCeap paddingCeap">
            <div className="iconCeap">
              <img
                className="imgCaruselCeap"
                src={`/images/ceap/funcion_${index}.png`}
              />
            </div>
            <div className="itemCaruselCeap">
              <div className="subh1home padBottomHome textLeft paddingCeap">
                {pos}. {title}
              </div>
              <div className="txtHome padBottomHomeMore textLeft">
                <ul>
                  {items.map((item, i) => <li key={i}>{item}</li>)}
                </ul>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Carousel_Funciones;
