import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Grid from "@material-ui/core/Grid";
import { Language } from "components/Language";

const Carousel_Personal_Copy = ({data}) => (
  <Carousel interval={5000}>
    {data.map((item, index) => (
      <Carousel.Item key={index} >
        <Grid
          container
          spacing={3}
          align="center"
          justifyContent="center"
          className="bgWhite rounded homeContainerCarrusel  carruselClaves "
        >
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <div>
              <div className="Oval" style={{ backgroundColor: item.color }}>
                <div className="claveNum">{item.num}</div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            <div className="subh1home padBottomHome textLeft">
              {item.titulo}
            </div>
            <div className="txtHome padBottomHomeMore textLeft">
              {item.definicion}
            </div>
            <div className="txtHome padBottomHomeMore textLeft">
              <a className="maindarkLink" href={"/user/diezClaves"}>
                <Language langKey="know_more"/>
              </a>
            </div>
          </Grid>
        </Grid>
      </Carousel.Item>
    ))}
  </Carousel>
); 

export default Carousel_Personal_Copy;
