import React, { useState } from "react";
import { Dialog, DialogContent, IconButton, Typography, makeStyles } from "@material-ui/core";
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import categoryOne from "assets/img/awards/category_one.webp";
import categoryTwo from "assets/img/awards/category_two.webp";
import categoryThree from "assets/img/awards/category_three.webp";
import categoryFour from "assets/img/awards/category_four.webp";
import { DialogTitle } from "components/DialogTitle";
import ReactPlayer from "react-player";
import { simpleParser } from "utils/parse-markdown";

const useStyle = makeStyles((theme) => ({
  categoryCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 8,
    maxWidth: 190,
    '& > *': {
      textAlign: 'center',
    },
    '& .bold': {
      fontWeight: 900,
      fontFamily: 'MuseoSans',
      fontSize: '1.1rem',
      lineHeight: 1.2,
      marginBottom: 5,
    },
    '& .semibold': {
      fontWeight: 700,
      fontFamily: 'MuseoSans',
    },
    '& img': {
      aspectRatio: 1 / 1,
      width: '100%',
      objectFit: 'cover',
    },
    '& a:hover': {
      color: theme.palette.primary.main,
    },
    '& > .image_cover': {
      position: 'relative',
      '& > .category_icon': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: '#fff',
        transform: 'translate(-50%, -50%)',
      }
    },
    '& .arrow_lines': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before, &::after': {
        content: "''",
        width: 'calc(50% - 15px)',
        height: 3,
        backgroundColor: 'var(--corpo-color)',
      },
    }
  },
  categoriesGrid: {
    display: 'flex',
    gap: 25,
    margin: '3.5rem 15px',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexWrap: 'wrap',
  },
  dialogContent: {
    padding: 0,
    position: 'relative',
    paddingTop: '56.25%', 
    overflow: 'hidden',
  }
}));

const categories = [
  {
    name: 'CONEXIÓN\nCON EL MUNDO PROFESIONAL',
    image: categoryOne,
    video: 'https://youtu.be/VoakIM9WCPs',
    school: 'ESCOLA\nVIROLAI',
    position: 'Orientación personal y profesional para el desarrollo del alumno',
    pdf: '/ES/awards/Categoría_Conexión_con_el_mundo_profesional.pdf'
  },
  {
    name: 'ORIENTACIÓN PERSONALIZADA\nY FAMILIA',
    image: categoryTwo,
    video: 'https://youtu.be/CLRMe0TcyHo',
    school: 'COLEGIO MONTPELLIER',
    position: 'Step By Step y Modelo Xcelence, un “maridaje” perfecto',
    pdf: '/ES/awards/Categoría_Orientación_personalizada_y_familia.pdf'
  },
  {
    name: 'INFORMACIÓN SOBRE ITINERARIOS',
    image: categoryThree,
    school: 'ALAMEDA INTERNATIONAL SCHOOL',
    video: 'https://youtu.be/GmhoC1mijF4',
    position: 'College counselling the future gate',
    pdf: '/ES/awards/Categoría_Información_sobre_itinerarios_de_formación.pdf'
  },
  {
    name: 'VINCULACIÓN DE LAS ASIGNATURAS CON ÁMBITOS PROFESIONALES',
    image: categoryFour,
    school: 'IES SALVADOR ALLENDE',
    video: 'https://youtu.be/YrKviSaYXwI',
    position: 'Navegando futuro',
    pdf: '/ES/awards/Categoría_Vinculación_de_asignaturas_con_ámbitos_profesionales.pdf'
  },
]

export function CategoryCard(
  { color, fullWidth }
) {
  const classes = useStyle({ color, fullWidth });
  const [url, setUrl] = useState();

  const handleClose = () => {
    setUrl();
  };

  return (
    <div className={classes.categoriesGrid}>
      {categories.map((item, i) => (
        <div className={classes.categoryCard}>
          <Typography className="semibold" variant="body2">CATEGORÍA:</Typography>
          <Typography style={{fontSize: '1.1rem', lineHeight: 1.2, padding: '0 10px'}} className="semibold" variant="body1">
            <span dangerouslySetInnerHTML={{__html: simpleParser(item.name) }}></span>
          </Typography>
          <div className="image_cover my-2">
            <img width="100%" src={item.image} />
            <IconButton className="category_icon" onClick={setUrl.bind(null, item.video)}>
              <PlayCircleOutlineRoundedIcon style={{fontSize: '3.5rem'}} fontSize="large" />
            </IconButton>
          </div>
          <div style={{minHeight: 120}}>
            <Typography className="bold" variant="body1">
              <span dangerouslySetInnerHTML={{__html: simpleParser(item.school) }}></span>
            </Typography>
            <Typography className="semibold mb-2" variant="body2">{item.position}</Typography>
          </div>

          <Typography className="semibold" variant="caption">DESCARGAR PDF</Typography>
          <div className="arrow_lines">
            <IconButton target="_blank" download href={item.pdf} size="small" color="primary">
              <ArrowDownwardRoundedIcon style={{fontSize: 28}} />
            </IconButton>
          </div>
        </div>
      ))}

      <Dialog keepMounted={false} fullWidth maxWidth="md" onClose={handleClose} open={!!url}>
        <span></span> {/* No remove */}
        <DialogContent className={classes.dialogContent}>
          <ReactPlayer
            style={{position: 'absolute', top: 0, left: 0}}
            children
            controls
            width='100%'
            playing={true}
            height='100%'
            url={url}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}
