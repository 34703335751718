import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import NavBarAuth from "../../NavBar/NavBarAuth.js";
import { ThemeProvider, createTheme } from "@material-ui/core";

import { TrainingWelcome } from "./TrainingWelcome.js";
import { TrainingVideos } from "./TrainingVideos.js";
import { BannerFooter } from "./components/BannerFooter.js";

import { TrainingBox } from "./components/TrainingBox.js";
import { YoutubeGridVideo } from "./components/YoutubeGridVideo.js";

const outerTheme = createTheme({
  shape: {
    borderRadius: 15,
  },
  palette: {
    primary: {
      main: '#dc0740',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'MuseoSans',
      'sans-serif',
    ],
    h4: {
      fontWeight: 500,
    },
    body1: {
      fontSize: 18,
    }
  },
});

export function Training() {
  return (
    <div>
      {localStorage.getItem("xcauth") === "true"
        ? <NavBarAuth />
        : <NavBar />
      }

      <ThemeProvider theme={outerTheme}>
        <TrainingWelcome />

        <div className="my-4">
          <TrainingVideos
            title={'Evaluación y coordinación'}
            details={[
              {
                keyNum: [1, 2],
                video: 'vx-2ROl_ftM',
              },
            ]}
            color="#3E7CBF"
          />

          <TrainingVideos
            title={'Alumnado y familias'}
            details={[
              {
                keyNum: 3,
                video: '1DhLL2qnco4',
              },
              {
                keyNum: 4,
                video: 'Qi-4IU6f144',
              },
              {
                keyNum: 5,
                video: 'pqYj3kYvxzA',
              },
            ]}
            color="#63b26b"
          />

          <TrainingVideos
            title={'Contenido curricular'}
            details={[
              {
                keyNum: 6,
                video: 'LK_bmjUIa-s',
              },
            ]}
            color="#996ba3"
          />

          <TrainingVideos
            title={'Mundo laboral'}
            details={[
              {
                keyNum: 7,
                video: 'jWeCRS1IMqs',
              },
              {
                keyNum: 8,
                video: 'YwXnro2G15I',
              },
              {
                keyNum: 9,
                video: 'h6BszvwIwIk',
              },
            ]}
            color="#DC4B59"
          />

          <TrainingVideos
            title={'Itinerarios postobligatorios'}
            details={[
              {
                keyNum: 10,
                video: 'PHCz4zJjF3U',
              },
            ]}
            color="#cd7e47"
          />

        </div>

        <TrainingBox
          title={"Descubre las tendencias en orientación con expertos"}
          background="#DC0740"
          color="#FFF"
          detail={
            <YoutubeGridVideo
              width={310}
              height={170}
              color='#0000'
              video='1F1q382fbH8'
            />
          }
        />

        <BannerFooter
          title={"Accede a las publicaciones de la Fundación para profundizar en Xcelence"}
          color="#000"
          background="#FFF"
        />

      </ThemeProvider>
    </div>
  );
}
