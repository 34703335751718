import React, { useMemo } from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import {CoockiesES, CoockiesCA} from "assets/policies";
import {useSelector} from "react-redux";

export default function Cookies() {
  const locale = useSelector((state) => state.setting.locale);

  const Componet = useMemo(() =>{
    const dictionary = {
      ES: CoockiesES,
      CA: CoockiesCA,
    }

    return dictionary[locale] ?? (() => <p>No have</p>)
  } , [locale]);

  return (
    <div>
      { localStorage.getItem("xcauth") &&
        localStorage.getItem("xcauth") === "true" 
          ? <NavBarAuth />
          : <NavBar />
      }

      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} className="privacityp">
          <Componet />
        </Grid>
      </Grid>
    </div>
  );
}
