import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import standardWheel from "assets/img/ES/wheel.gif";
import { useStyles } from "./dashboard.style.js";
import { KeyResultGraphic } from "./components/KeyResultGraphic.js";
import { WhatIsXcelence } from "./components/WhatIsXcelence.js";
import { XcelenceGoal } from "./components/XcelenceGoal.jsx";
import { PagePresentation } from "./components/PagePresentation.js";
import { WheelReport } from "./components/WheelReport.jsx";
import { FindingSection } from "./components/FindingSection.jsx";
import { PageIFrameSection } from "./components/PageIFrameSection.js";
import { CarouselTestimonial } from "./components/CarouselTestimonial.jsx";
import { Footer } from "./components/Footer.js";
import NavBarAuth from "views/NavBar/NavBarAuth.js";
import GlobalFooter from "components/Footer/Footer.js";
import { ThemeProvider, createTheme } from "@material-ui/core";

const outerTheme = (theme) => createTheme({
  ...theme,
  shape: {
    borderRadius: 15,
  },
  palette: {
    primary: {
      main: '#dc0740',
    },
    secondary: {
      main: '#fff',
    },
  },
  overrides: {
    ...theme.overrides,
    MuiInputBase: {
      root: {
        backgroundColor: '#fff',
      }
    },
    MuiFormLabel: {
      root: {
        color: '#fff'
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#fff',
      },
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: 15,
        paddingBottom: 15,
      }
    },
    MuiButton: {
      ...theme.overrides.MuiButton,
      outlinedSecondary: {
        padding: '8px 20px',
        border: '2px solid #fff',
        color: '#fff',
        '&:hover, &:focus': {
          border: '2px solid #0000',
          backgroundColor: '#fff',
          color: '#dc0740',
        }
      },
    },
    MuiIconButton: {
      colorPrimary: {
        backgroundColor: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
      }
    },
    MuiFormLabel: {
      root: {
        color: '#fff',
        fontSize: '0.85rem',
      }
    }
  },
  typography: {
    fontFamily: [
      'MuseoSans',
      'sans-serif',
    ],
    h5: {
      textTransform: 'none',
      fontWeight: 600,
      opacity: 0.87,
      fontSize: '1.4rem',
    },
    h6: {
      textTransform: 'none',
      opacity: 0.87,
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    caption: {
      textTransform: 'uppercase',
      lineHeight: 1.8,
    }
  }
});

export function ReportPage() {
  const classes = useStyles();

  return (
    <div>
      {localStorage.getItem("xcauth") === "true"
        ? <NavBarAuth />
        : <NavBar />
      }

      <ThemeProvider theme={outerTheme}>
      <div className={classes.dashboardContainer}>
        <PagePresentation />

        <WheelReport />

        <FindingSection />

        <KeyResultGraphic />

        <WhatIsXcelence />

        <CarouselTestimonial />

        <XcelenceGoal />

        <PageIFrameSection />

        <Footer />
      </div>
      </ThemeProvider>

      <GlobalFooter />
    </div>
  );
}
