import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { number, string } from "prop-types";

const useStyles = makeStyles((theme) => ({
  keyItem: ({color}) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    backgroundColor: '#fff',
    borderRadius: 35,
    padding: 6,
    paddingRight: 20,
    flexWrap: 'wrap',

    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      '&:hover': {
        backgroundColor: color,
        color: '#fff',
      },
    }
  }),
  rankingItem: ({color, first, second, third}) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 21,
    position: 'relative',
    height: 28,
    justifyContent: 'space-between',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: 'initial',
      flexGrow: 2,
      height: 42,

      '$keyItem:hover > &': {
        display: 'none'
      },
    },

    '& > div': {
      height: '100%',
    },
    '& > div.first': {
      width: first + '%',
      position: 'absolute',
      left: 0,
      backgroundColor: '#fff4',
      borderRadius: 25,
    },
    '& > div.second': {
      width: second + '%',
      position: 'absolute',
      left: 0,
      backgroundColor: '#ffffff35',
      borderRadius: 25,
    },
    '& > div.third': {
      width: third + '%',
      backgroundColor: color,
      borderRadius: 25,
    }
  }),
  avatar: ({color}) => ({
    padding: 12,
    borderRadius: '50%',
    backgroundColor: color,
    display: 'flex',
    alignItems: 'center',
    height: 50,

    '& img': {
      width: 28,
    }
  }),
  detail: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 20,
    flexGrow: 2,

    [theme.breakpoints.up('md')]: {
      '$keyItem:hover > &': {
        display: 'flex',
      },
    }
  },
  description: {
    width: 'calc(100% - 75px)',
    display: 'initial',

    [theme.breakpoints.up('md')]: {
      width: 65,

      '& .info': {
        display: 'none'
      }
    }
  }
}));

export const KeyGrowing = (props) => {
  const {
    first = 45.4,
    second = 65,
    third = 65,
    color = '#b4b8d0',
    icon,
    description,
  } = props;
  
  const classes = useStyles({color, second, third, first});

  const [title, detail] = description.split('\n'); 

  return (
    <div className={classes.keyItem}>
      <div className={classes.avatar}>
        <img src={icon} />
      </div>
      <div className={classes.description}>
        <Typography>{title}</Typography>
        <Typography className='info'>{detail}</Typography>
      </div>
      <div className={classes.rankingItem}>
        <div className="first"></div>
        <div className="second"></div>
        <div className="third"></div>
      </div>
      <div className={classes.detail}>
        <Typography className="ml-2">
          {detail}
        </Typography>
      </div>
    </div>
  );
}

KeyGrowing.propTypes = {
  first: number,
  after: number,
  color: string,
  icon: string,
  description: string,
};