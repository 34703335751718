import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Language } from "components/Language";
import stainImage from "assets/img/stain_blue.webp";
import findingOneImage from "assets/img/finding_one.jpg";
import findingTwoImage from "assets/img/finding_two.jpg";
import findingThreeImage from "assets/img/finding_three.jpg";
import findingFourImage from "assets/img/finding_four.jpeg";
import { FlipCard } from "./FlipCard";
import { CarouselFinding } from "./CarouselFinding";

const useStyles = makeStyles(theme => ({
  presentation: {
    backgroundImage: `url(${stainImage})`,
    backgroundPosition: 'right 120%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '& .container': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& .finding-title': {
      position: 'absolute',
      left: 20,
      width: 210,
      top: 10,

      [theme.breakpoints.up('sm')]: {
        position: 'static',
        marginLeft: '1.2rem',
        width: 'initial',
        marginBottom: '1.5rem',
      },
    },
    '& .flip-boxes': {
      display: 'none',
      flexWrap: 'wrap',
      gap: 20,
      marginBottom: '1.5rem',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
  },
}));

const findings = [
  {
    title: "report_page.finding_one_title",
    description: "report_page.finding_one_description",
    image: findingOneImage,
  },
  {
    title: "report_page.finding_two_title",
    description: "report_page.finding_two_description",
    image: findingTwoImage,
  },
  {
    title: "report_page.finding_three_title",
    description: "report_page.finding_three_description",
    image: findingThreeImage,
  },
  {
    title: "report_page.finding_four_title",
    description: "report_page.finding_four_description",
    image: findingFourImage,
  },
]

export function FindingSection() {
  const classes = useStyles();

  return (
    <div className={classes.presentation}>
      <div className="container">
        <Typography className="finding-title" variant="h5">
          <Language langKey="report_page.findings_title" />
        </Typography>

        <div className="flip-boxes">
          {findings.map((item,i) => 
            <FlipCard
              key={i}
              title={item.title}
              description={item.description}
              image={item.image}
            />
          )}
        </div>

        <CarouselFinding content={findings} />
      </div>
    </div>
  );
}
